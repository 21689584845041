import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import mailImage from "../../../../assets/images/mailIcon.png";
import LabelInputLeftIcon from "../../../@common/Input/LabelInputLeftIcon";
import FormCheckboxes from "../../../@common/Input/chexboxes";
import { Checkbox, IconButton } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import phoneIcon from "../../../../assets/images/phoneIcon.png";
import SixDigitCode from "../../Input/SixDigitCode/SixDigitCode";
import { getCookiesList } from "../../../../utils/cookiesHelper";
import {
    call_api_function,
} from "../../../../utils/verifyAPICalls";
import {
    emailPattern,
} from "../../../../utils/InputPatterns";
import AlertModal from "../../../@common/Modal/AlertModal";
import CustomSpinner from "../../../@common/CustomSpinner/customSpinner";
import { useTranslation } from "react-i18next";
import { processCompanyData } from "../../../../utils/getColorHelper";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LogoModalLoader from "../WithBureauModal/LogoModalLoader";
import { dczEVerificarLegal } from "../../../../utils/EModuleVerifyApiCall";
import { checkCupons, getReport, verReport } from "../../../../utils/locales/EbureauHelper";

export default function EmoduleWithBureauModal(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMdOrLess = useMediaQuery(theme.breakpoints.down("md")); // Check if screen size is md or smaller
    const isBetween1024And1440 = useMediaQuery(
        `(min-width: 1024px) and (max-width: 1440px)`
    ); // Check if screen size is between 1024px and 1440px
    const isGreaterThan1440 = useMediaQuery(theme.breakpoints.up("xl")); // Check if screen size is greater than 1440px

    const style = {
        "*::-webkit-scrollbar": {
            width: "6px !important", // Adjust as needed
            borderRadius: "3px !important",
        },

        "*::-webkit-scrollbar-track": {
            backgroundColor: "#E4EFEF !important", // Adjust as needed
        },

        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#4165f1d4 !important", // Adjust as needed
            borderRadius: "3px !important", // Rounded corners
        },
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        maxWidth: "95%",
        overflowX: "auto",
        overflowY: "scroll",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
        height: isMdOrLess
            ? "70vh"
            : isBetween1024And1440
                ? "70vh"
                : isGreaterThan1440
                    ? "55vh"
                    : "60vh", // Change the height value as needed
    };

    const subjectList = [
        {
            label: t("guestTermsCondition.subjectburo"),
            value: "Subject is found present to authenticate process",
        },
    ];
    const {
        tagLine = t("guestTermsCondition.tagline"),
        emailLabel = t("guestTermsCondition.emailLabel"),
        subjectCheckboxShow = false,
        emailBoxButtonShow = false,
        mobileLabel = t("thanks.Email receipt"),
        dataCurpZero,
        nbData,
        onConfirmation,
        subsections_dicts,
        cbcId
    } = props;

    const navigate = useNavigate();
    //cookie state
    const [cookieList, setCookieList] = useState([]);
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
    const [showtext, setShowtext] = useState("");
    const handleClose = () => {
        setSubjectEmail("");
        setTagShow(true);
        setEmailSent(false);
        setPersonaPresente(false);
        setSubjectFoundSelect("");
        setEmailButtonShow(true);
        setSubjectEmail("");
        setSubjectPhone("");
        setauthorizationShow(false);
        setIsCheckboxChecked(false);
        setIsButtonClicked(false);
        setIsCheckboxCheckedSecond(false);
        setTermsConditionShow(false);
        props.setWithBureauOpen(false);
    };
    const [subjectFoundSelect, setSubjectFoundSelect] = useState("");
    const [termsConditionShow, setTermsConditionShow] = useState(false);
    const [prefix, setPrefix] = useState('+52');
    const [subjectEmail, setSubjectEmail] = useState("");
    const [subjectPhone, setSubjectPhone] = useState("");
    const [nip, setNip] = useState("");
    const [personaPresente, setPersonaPresente] = useState(false);
    const [tagShow, setTagShow] = useState(true);
    const [emailSent, setEmailSent] = useState(false);
    const [authorizationShow, setauthorizationShow] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [isCheckboxCheckedSecond, setIsCheckboxCheckedSecond] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [emailButtonShow, setEmailButtonShow] = useState(true);
    const [spinnerVisibility, setSpinnerVisibility] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    // Side Effects
    useEffect(() => {
        const userCookiesList = getCookiesList("userInfo");
        if (userCookiesList.length > 0) {
            setCookieList(userCookiesList);
        }
    }, []);
    const handleCheckboxChange = (e) => {
        if (!isCheckboxChecked) {
            // Only allow checking the checkbox if it's not already checked
            setIsCheckboxChecked(e.target.checked);
        }
    };
    const subjectFoundHandler = (e) => {
        setSubjectFoundSelect(
            subjectFoundSelect == e.target.value ? "" : e.target.value
        );
        setPersonaPresente(subjectFoundSelect == e.target.value ? false : true);
    };

    const handleResponseError = (e) => {
        if (
            e.message.includes("timeout of 61000ms exceeded") ||
            e.message.includes("timeout of 20000ms exceeded") ||
            e.message.includes("timeout of 71000 exceeded")
        ) {
            setShowAlert(true);
            setShowtext("Alcanzaste el tiempo límite de espera. Intenta de nuevo.");
        } else {
            setShowAlert(true);
            setShowtext(t("thanks.check"));
        }
    };

    const verificarPhono = async () => {
        var dataLegal = new FormData();
        dataLegal.append("usrMail", cookieList[3]);
        dataLegal.append("mail", subjectEmail);
        dataLegal.append("phono", prefix + subjectPhone);
        dataLegal.append("caso", "");

        if (!personaPresente) {
            dataLegal.set("caso", "caso4");
        } else {
            dataLegal.set("caso", "caso5");
        }

        // call api verficar_legal
        try {
            const verifyResponse = await call_api_function(
                process.env.REACT_APP_BASE_URL2 + "legal_e_module_auth",
                dataLegal
            );
            if (verifyResponse.data == "existe" || verifyResponse.data == "reenvio") {
                //some diabling of btn and navigate to "/"
                setIsLoadingModal(false);
                setShowAlert(true);
                setShowtext("existe");
            } else if (verifyResponse.data == "telefono no valido") {
                // this.telLegal = false; make phone legality false
                setIsLoadingModal(false);
                setShowAlert(true);
                setShowtext(t("thanks.tele"));
            } else if (verifyResponse.data == "error bd") {
                setIsLoadingModal(false);
                setShowAlert(true);
                setShowtext("no Connection");
            } else if (verifyResponse.data) {
                const id_sujeto = verifyResponse.data;
                dataCurpZero["sujeto_id"] = id_sujeto;
                dataCurpZero["sujeto_idHomonimo"] = "0";
                dataCurpZero["id_CB"] = nbData.cb_id;
                dataCurpZero["secciones_consulta"] = JSON.stringify(subsections_dicts);
                const dCZ = dataCurpZero;
                const dczResponse = await dczEVerificarLegal(dCZ);
                if (dczResponse.data) {
                    const subsections_dict = dczResponse.data.secciones_consulta;
                    if (dataLegal.get("caso") == "caso4") {
                        handleClose();
                        setIsLoadingModal(false);
                        props.setEmailSentModalOpen(true);
                    } else {
                        // open second part of modal box
                        setIsLoadingModal(false);
                        setTermsConditionShow(true);
                        setEmailSent(true);
                    }
                } else {
                    setIsLoadingModal(false);
                    setShowAlert(true);
                    setShowtext("error");
                }
            }
        } catch (error) {
            if (error.message === "timeout of 30000ms exceeded") {
                setShowAlert(true);
                setShowtext("Alcanzaste el tiempo", "límite de espera");
            } else {
                setShowAlert(true);
                setShowtext("Comprueba", "tu conexión");
            }
            setIsLoadingModal(false);
        }
    };

    const sendMailAndEmitThankyouMessage = (data) => {
        handleClose();
        onConfirmation(data);
    };

    const isValidVerificationCode = (value) => {
        // perform verification logic
        return value.length === 6 && /^\d+$/.test(value);
    };

    const verificarNip = async () => {
        //validate nip function
        // initial validation NOT equals to phone<10, nip<6, email should be in email format

        if (isValidVerificationCode(nip)) {
            //prepare payload if validated
            var dataLegal = {
                mail: subjectEmail,
                phono: prefix + subjectPhone,
                nip: nip,
                vlid: dataCurpZero["sujeto_id"]
            };
            var formData = new FormData();

            // Append each key-value pair from dataLegal to the FormData object
            for (var key in dataLegal) {
                formData.append(key, dataLegal[key]);
            }
            try {
                const response = await call_api_function(
                    process.env.REACT_APP_BASE_URL2 + "legal-nip_e_module",
                    formData
                );
                if (response.data == "nip incorrecto") {
                    setSpinnerVisibility(false);
                    setShowAlert(true);
                    setShowtext(t("guestTermsCondition.IncorrectPIN"));
                } else if (response.data) {
                    // call checkcupon

                    const cupon = await checkCupons("Companies", cookieList[3]);
                    if (cupon == "error") {
                        setSpinnerVisibility(false);
                        setShowAlert(true);
                        setShowtext("Tus cupones Empresas son insuficientes");
                    } else {
                        let resultados = await getReport(
                            "CB",
                            cupon,
                            nbData,
                            cbcId,
                            false,
                            undefined,
                            dataCurpZero["sujeto_id"],
                            cookieList[3],
                            dataCurpZero
                        );
                        checkCupons("Companies", cookieList[3]);
                        if (personaPresente) {
                            await verReport(dataCurpZero["sujeto_id"]);
                            setSpinnerVisibility(false);
                            navigate("/Ereport", {
                                state: {
                                    espData: resultados,
                                    sub_sections_data: subsections_dicts,
                                    vlid: dataCurpZero["sujeto_id"],
                                    nombre: resultados["razon_social"],
                                    usuarioCupon: "CB",
                                    cbcId:cbcId

                                },
                            });
                        } else {
                            setSpinnerVisibility(false);
                            sendMailAndEmitThankyouMessage(resultados);
                        }
                    }
                }
            } catch (e) {
                setSpinnerVisibility(false);
                handleResponseError(e);
            }
        } else {
            setSpinnerVisibility(false);
            setShowAlert(true);
            setShowtext("kindly enter the PIN");
        }
    };
    const sendButtonHandler = () => {
        setTagShow(personaPresente);
        setIsLoadingModal(true);
        verificarPhono();
    };

    const acceptHandler = () => {
        setauthorizationShow(true);
        setIsButtonClicked(true);
    };

    const authorizeHandler = () => {
        setSpinnerVisibility(true);
        verificarNip();
    };
    const handlePhoneChange = (event) => {
        const inputValue = event.target.value;
        let cleanedValue = inputValue;
        cleanedValue = inputValue.replace(/[^0-9]/g, "");
        cleanedValue = cleanedValue.substring(0, 10);
        setSubjectPhone(cleanedValue);
        validateForm(inputValue, subjectEmail);
    };

    const handleEmailChange = (event) => {
        const inputValue = event.target.value.replace(/\s/g, '');
        setSubjectEmail(inputValue);
        validateForm(subjectPhone, inputValue);
    };

    const validateForm = (phone, email) => {
        if (
            emailPattern.test(email) &&
            phone.trim() !== "" &&
            email.trim() !== ""
        ) {
            setEmailButtonShow(false); // Both are valid and not empty, enable the button
        } else {
            setEmailButtonShow(true);
        }
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const scrollTargetRef = useRef(null);

    useEffect(() => {
        if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, [termsConditionShow, authorizationShow]);

    return (
        <Fragment>

            {spinnerVisibility &&
                <CustomSpinner visible={spinnerVisibility} timer={100} />
            }

            {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
            <LogoModalLoader isOpenLogoModal={isLoadingModal} />
            <Modal
                open={props.withBureauOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            position: "fixed",
                            top: 0,
                            right: 0,
                            height: "100%",
                            overflow: "auto",
                        }}
                    >
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            height: "100%",
                            overflow: "auto",
                            height: "100%",
                            overflow: "auto",
                        }}
                    >
                        {tagShow ? (
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontFamily: "Gilroy-Medium",
                                    color: "rgba(27, 32, 44, 0.6)",
                                    textAlign: "center",
                                    mb: 4,
                                }}
                            >
                                {tagLine}
                            </Typography>
                        ) : null}
                        <Box
                            sx={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "rgba(27, 32, 44, 0.1)",
                                borderRadius: "6px",
                                p: 2,
                            }}
                        >
                            <Box>
                                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                    <Typography
                                        sx={{
                                            color: "neutral.900",
                                            fontSize: 16,
                                            fontFamily: "Gilroy-Medium",
                                            textAlign: "center",
                                        }}
                                    >
                                        {tagShow ? mobileLabel : t("thanks.Cellnumberreceiving")}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{ width: { xs: "90%", sm: "70%" }, mx: "auto", pt: 2 }}
                                >
                                    <LabelInputLeftIcon
                                        isPhone={true}
                                        prefix={prefix}
                                        setPrefix={setPrefix}
                                        mailIcon={phoneIcon}
                                        type="number"
                                        label=""
                                        name="mnumber"
                                        id="mnumber"
                                        placeholder={t("placeholders.number")}
                                        inputValue={subjectPhone}
                                        onChange={handlePhoneChange}
                                        maxLength={10}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                    <Typography
                                        sx={{
                                            color: "neutral.900",
                                            fontSize: 16,
                                            fontFamily: "Gilroy-Medium",
                                            textAlign: "center",
                                        }}
                                    >
                                        {tagShow ? emailLabel : t("thanks.Email receipt")}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{ width: { xs: "90%", sm: "70%" }, mx: "auto", pt: 2 }}
                                >
                                    <LabelInputLeftIcon
                                        mailIcon={mailImage}
                                        type="text"
                                        label=""
                                        name="emailAddress"
                                        id="emailAddress"
                                        placeholder={t("placeholders.email")}
                                        inputValue={subjectEmail}
                                        onChange={handleEmailChange}
                                    />
                                </Box>
                            </Box>
                            {subjectCheckboxShow ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 3,
                                    }}
                                >
                                    <FormCheckboxes
                                        topLabel={false}
                                        sx_radio={{ padding: "3px 9px" }}
                                        reasonsGrouping={subjectList}
                                        reasonSelect={subjectFoundSelect}
                                        onChange={subjectFoundHandler}
                                    />
                                </Box>
                            ) : null}
                            {emailBoxButtonShow ? (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            mt: 4,
                                        }}
                                    >
                                        <Button
                                            disabled={emailButtonShow}
                                            onClick={sendButtonHandler}
                                            sx={{
                                                backgroundColor: "primary.main",
                                                py: 1,
                                                px: { xs: 4, sm: 8 },
                                                fontSize: "18px",
                                                fontFamily: "Gilroy-Medium",
                                                textTransform: "capitalize",
                                            }}
                                            variant="contained"
                                        >
                                            {emailSent ? t("buttons.Resend") : t("buttons.Send")}
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            mt: 4,
                                        }}
                                    >
                                        <Button
                                            onClick={handleClose}
                                            style={{
                                                color: "rgba(27, 32, 44, 0.6)",
                                                fontSize: 16,
                                                fontFamily: "Gilroy-Medium",
                                                textDecoration: "underline",
                                                textTransform: "capitalize",
                                            }}
                                            variant="text"
                                        >
                                            {t("buttons.back")}
                                        </Button>
                                    </Box>
                                </>
                            ) : null}
                        </Box>
                        {termsConditionShow ? (
                            <>
                                <div ref={scrollTargetRef}>
                                    <Box sx={{ my: 4 }}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isCheckboxChecked}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                }
                                                label={
                                                    <Box component={"span"} sx={{ color: "#4165F6" }}>
                                                        {t("guestTermsCondition.accept")}
                                                        <Box
                                                            component={"span"}
                                                            sx={{ color: "gray", ml: 1 }}
                                                        >
                                                            <a
                                                                href="/PrivacyNotice"
                                                                target={"_blank"}
                                                                style={{ color: "#656DFC" }}
                                                            >
                                                                {`${t("guestTermsCondition.Privacy Notice")} `}
                                                            </a>
                                                            &
                                                            <a
                                                                href="/TermsAndConditions"
                                                                target={"_blank"}
                                                                style={{ color: "#656DFC" }}
                                                            >
                                                                {` ${t(
                                                                    "guestTermsCondition.Terms and Conditions"
                                                                )}`}
                                                            </a>
                                                            {` ${t("guestTermsCondition.newTerm1")}`}
                                                            {` ${t("guestTermsCondition.newTerm2")} `}
                                                            <a
                                                                href="https://micuenta.infonavit.org.mx/"
                                                                target={"_blank"}
                                                                style={{ color: "#656DFC" }}
                                                                rel="noreferrer"
                                                            >
                                                                {`(https://micuenta.infonavit.org.mx/)`}
                                                            </a>
                                                            {` ${t("guestTermsCondition.newTerm3")} `}
                                                        </Box>
                                                    </Box>
                                                }
                                            />
                                        </FormGroup>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                mt: 4,
                                            }}
                                        >
                                            <Button
                                                onClick={acceptHandler}
                                                sx={{
                                                    backgroundColor: "primary.main",
                                                    py: 1,
                                                    px: { xs: 4, sm: 8 },
                                                    fontSize: "18px",
                                                    fontFamily: "Gilroy-Medium",
                                                    textTransform: "capitalize",
                                                }}
                                                variant="contained"
                                                disabled={!isCheckboxChecked || isButtonClicked}
                                            >
                                                {t("buttons.Accept")}
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                            </>
                        ) : null}
                        {authorizationShow ? (
                            <>
                                <div ref={scrollTargetRef}>
                                    <hr
                                        style={{
                                            background: "rgba(255, 255, 255, 0.2)",
                                            borderColor: "rgba(255, 255, 255, 0.2)",
                                        }}
                                    />
                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked={isCheckboxCheckedSecond}
                                                        onChange={(e) =>
                                                            setIsCheckboxCheckedSecond(e.target.checked)
                                                        }
                                                    />
                                                }
                                                label={
                                                    <Box component={"span"} sx={{ color: "#4165F6" }}>
                                                        {" "}
                                                        {t("guestTermsCondition.authorization")}{" "}
                                                        <Box
                                                            component={"span"}
                                                            sx={{ color: "gray", ml: 1 }}
                                                        >
                                                            {t("guestTermsCondition.authorization1")}
                                                        </Box>
                                                    </Box>
                                                }
                                            />
                                        </FormGroup>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                color: "text.opacity",
                                                fontFamily: "Gilroy-Medium",
                                                mt: 4,
                                            }}
                                        >
                                            {t("guestTermsCondition.authorization2")}
                                        </Typography>
                                    </Box>
                                    <hr
                                        style={{
                                            background: "rgba(255, 255, 255, 0.2)",
                                            borderColor: "rgba(255, 255, 255, 0.2)",
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 2,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "neutral.900",
                                                fontSize: 16,
                                                fontFamily: "Gilroy-Medium",
                                                textAlign: "center",
                                                mb: 2,
                                            }}
                                        >
                                            {t("guestTermsCondition.pinno")}
                                        </Typography>
                                        <SixDigitCode onCodeChange={(value) => setNip(value)} />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            mt: 4,
                                        }}
                                    >
                                        <Button
                                            disabled={
                                                !isCheckboxCheckedSecond ||
                                                !isValidVerificationCode(nip)
                                            }
                                            onClick={authorizeHandler}
                                            sx={{
                                                backgroundColor: "primary.main",
                                                py: 1,
                                                px: { xs: 4, sm: 8 },
                                                fontSize: "18px",
                                                fontFamily: "Gilroy-Medium",
                                                textTransform: "capitalize",
                                            }}
                                            variant="contained"
                                        >
                                            {t("buttons.Authorize")}
                                        </Button>
                                    </Box>
                                </div>
                            </>
                        ) : null}
                    </Box>
                </Box>
            </Modal>
        </Fragment>
    );
}
