import { Box, Button, Grid, List, ListItem, ListItemText, Modal, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomSpinner from "../../../@common/CustomSpinner/customSpinner";
import { useTranslation } from 'react-i18next';
import underLine from "../../../../assets/images/under-line.png";
import axios from 'axios';
import { getCookiesList } from "../../../../utils/cookiesHelper";
import ThankYouMessage from '../ThankYouMessage';
import LogoModalLoader from '../WithBureauModal/LogoModalLoader';
import AlertModal from '../AlertModal';
import EmoduleTermsAndCondition from './EmoduleTermsAndCondition';
import EmailSuccessfullySent from '../EmailSuccessfullySent';
import { checkCupons, timeSleep } from '../../../../utils/locales/EbureauHelper';
import EmoduleWithBureauModal from './EmoduleWithBureauModal';

const CompanyListModal = (props) => {
    const { t } = useTranslation()
    const { companyModalOpen,
        setCompanyModalOpen,
        companyListData,
        sigerCeroId,
        emoduleData,
        termsModal
    } = props

    const [selectedCompany, setSelectedCompany] = useState({});
    
    const [nbdata, setNBData] = useState({});
    const [DataSend, setDataSend] = useState({});
    const [withoutBureauModalOpen, setWithoutBureauModalOpen] = useState(false);
    const [cookieList, setCookieList] = useState([]);
    const [isLogoModalOpen, setLogoModalOpen] = useState(false); 
    // const [spinnerVisibility, setSpinnerVisibility] = useState(false);
    const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
    const [showtext, setShowtext] = useState("");
    const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
    const [reportData, setReportData] = useState({});
    const [emailSentModalOpen, setEmailSentModalOpen] = useState(false);
    const [subsectionDicts, setSubSectionDicts] = useState(subsections_dict);
    const [curpData, setcurpData] = useState("");
    const [estadoData, setestadoData] = useState("");
    const [nssData, setnssData] = useState("");
    const [nss1Data, setnss1Data] = useState("");
    const [cname, setcname] = useState("");
    const [edad, setedad] = useState("");
    const [customeReport, setCustomReport] = useState(false);
    const [withBureauOpen, setWithBureauOpen] = useState(false);
    const [cbcId, setCbcId] = useState("")
    const [timerTimeoutStatus, setTimerTimeoutStatus] = useState(false);

    const handleSelect = (companyData) => {
        setSelectedCompany(companyData);
    };

    var subsections_dict = {
        A: true,
        B: true,
        C: true,
        D: true,
        E: true,
        F: true,
        G: true,
        sub_a1: true,
        sub_a2: true,
        sub_a3: true,
        sub_a4: true,
        sub_a5: true,
        sub_a6: true,
        sub_a7: true,
        sub_a8: true,
        sub_b1: true,
        sub_c1: true,
        sub_c2: true,
        sub_c3: true,
        sub_c4: true,
        sub_c5: true,
        sub_c6: true,
        sub_c7: true,
        sub_c8: true,
        sub_c9: true,
        sub_c10: true,
        sub_c11: true,
        sub_c12: true,
        sub_d1: true,
        sub_d2: true,
        sub_d3: true,
        sub_d4: true,
        sub_d5: true,
        sub_d6: true,
        sub_d7: true,
        sub_d8: true,
        sub_d9: true,
        sub_d10: true,
        sub_d11: true,
        sub_e1: true,
        sub_e2: true,
        sub_e3: true,
        sub_e4: true,
        sub_e5: true,
        sub_e6: true,
        sub_e7: true,
        sub_e8: true,
        sub_e9: true,
        sub_e10: true,
        sub_e11: true,
        sub_f1: true,
        sub_f2: true,
        sub_g1: true,
        sub_g2: true,
        sub_g3: true,
        sub_g4: true,
    };
    const openThankYouModal = (data) => {
        setReportData(data);
        setIsThankYouModalOpen(true);
    };

    const theme = useTheme();
    const isMdOrLess = useMediaQuery(theme.breakpoints.down("md")); // Check if screen size is md or smaller
    const isBetween1024And1440 = useMediaQuery(`(min-width: 1024px) and (max-width: 1440px)`); // Check if screen size is between 1024px and 1440px
    const isGreaterThan1440 = useMediaQuery(theme.breakpoints.up("xl")); // Check if screen size is greater than 1440px
    const style = {
        "*::-webkit-scrollbar": {
            width: "6px !important", // Adjust as needed
            borderRadius: "3px !important",
        },

        "*::-webkit-scrollbar-track": {
            backgroundColor: "#E4EFEF !important", // Adjust as needed
        },

        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#4165f1d4 !important", // Adjust as needed
            borderRadius: "3px !important", // Rounded corners
        },
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        maxWidth: "95%",
        // overflowX: "auto",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "20px",
    };

    const handleClose = () => {
        props.setCompanyModalOpen(false)
    }

    // Logo modal Handlers
    const handleOpenLogoModal = () => {
        // setSpinnerVisibility(true);
        setLogoModalOpen(true);
    };

    const handleCloseLogoModal = () => {
        // setSpinnerVisibility(false);
        setLogoModalOpen(false);
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleCompanyScraper = async () => {
        handleOpenLogoModal();
        const endpoint = process.env.REACT_APP_BASE_URL2 + "siger_fetch_data";
        const params = {
            siger_cero_id: sigerCeroId,
            fme: selectedCompany.fme,
            razon_social: selectedCompany.razon_social
        }
        try {
            const response = await axios.post(endpoint, params, {
                headers: {'Content-Type': 'application/json'}
            });

            if (response?.data?.status == 'ok') {
                await checkFetchStatus();
            }
            else if (response?.data?.status == 'error') {
                handleCloseLogoModal();
                setShowtext(response.data.message);
                setShowAlert(true);
            }
            else {
                handleCloseLogoModal();
                setShowAlert(true);
                setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
            }
        }
        catch (error) {
            console.log(error, "======error handleCompanyScraper");
            handleCloseLogoModal();
            setShowAlert(true);
            setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
        }
    }

    const checkFetchStatus = async () => {
        const endpoint = process.env.REACT_APP_BASE_URL2 + "siger_wait_for_response";
        
        let retryCount = 0;
        while (true) {
            try {
                const response = await axios.post(endpoint, {
                    siger_cero_id: sigerCeroId,
                    step_no: 2,
                }, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                if (response?.data?.status == 'pending') {
                    ;
                } else if (response?.data?.status == 'ok') {
                    handleCloseLogoModal();
                    setCompanyModalOpen(false)
                    setDataSend(emoduleData);
                    validarTipoConsulta();
                    setCbcId(response?.data?._id)
                    setNBData({"cb_id": response?.data?._id})
                    break;
                }
                else if(response?.data?.status == 'error'){
                    handleCloseLogoModal();
                    setShowAlert(true);
                    setShowtext(response.data.error);
                    break;
                }
                else {
                    handleCloseLogoModal();
                    setShowAlert(true);
                    setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
                    break;
                }
            }
            catch (error) {
                handleCloseLogoModal();
                setShowAlert(true);
                setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
                break;
            }
            
            if (retryCount < 3) {
                await timeSleep(3000);
            } else {
                await timeSleep(10000);
            }

            if (timerTimeoutStatus) break;
            
            retryCount++;
        }
    }

    const validarTipoConsulta = async () => {
        // decide which modal box to open
        const cupon = emoduleData.usuario_cupon;
        const checkCuponsResponse = await checkCupons(cupon, cookieList[3]);
        if (checkCuponsResponse === "error") {
            handleCloseLogoModal();
            setShowAlert(true);
            let messageData;
            if (localStorage.getItem("selectedLanguage") == "en") {
                messageData =
                    "We apologize, but you have run out of query coupons. Please contact your administrator or purchase more coupons to continue using our services. If you need assistance, call us at +52 55 3332 6533 or email us at contacto@searchlook.mx.";
            } else {
                messageData =
                    "Lo sentimos, has agotado tus cupones de consulta. Por favor, contacta a tu administrador o adquiere más cupones para continuar utilizando nuestros servicios. Si necesitas ayuda, llámanos al 55 3332 6533 o escríbenos a contacto@searchlook.mx.";
            }
            setShowtext(messageData);
        }

        if (cupon == "CB") {
            if (!customeReport) {
                const sub_dict = resetSubsections("CB");
                setSubSectionDicts(sub_dict);
            }
            if (termsModal == true) {
                setWithBureauOpen(true);
            }
            else {
                setWithoutBureauModalOpen(true);

            }
        } else if (cupon == "SB") {
            if (!customeReport) {
                const sub_dict = resetSubsections("SB");
                setSubSectionDicts(sub_dict);
            }
            if (termsModal == true) {
                setWithBureauOpen(true);
            }
            else {
                setWithoutBureauModalOpen(true);

            }
        }
    };

    const resetSubsections = (tipoCupon) => {
        var sub_dict = subsections_dict;

        if (tipoCupon == "CB") {
            Object.keys(sub_dict).forEach(function (key) {
                sub_dict[key] = true;
            });
        } else if (tipoCupon == "SB") {
            Object.keys(sub_dict).forEach(function (key) {
                sub_dict[key] = true;
            });
            sub_dict["D"] =
                sub_dict["sub_d1"] =
                sub_dict["sub_d2"] =
                sub_dict["sub_d3"] =
                sub_dict["sub_d4"] =
                sub_dict["sub_d5"] =
                sub_dict["sub_d6"] =
                sub_dict["sub_d7"] =
                sub_dict["sub_d8"] =
                sub_dict["sub_d9"] =
                sub_dict["sub_g3"] =
                true;
        }

        return sub_dict;
    };

    // Effects
    useEffect(() => {
        const userCookiesList = getCookiesList("userInfo");
        if (userCookiesList.length > 0) {
            setCookieList(userCookiesList);
        }
    }, []);

    useEffect(() => {
        if (companyListData?.length) {
            setSelectedCompany(companyListData[0]);
        }
    }, [companyListData]);

    const onTimerTimeout = () => {
        setTimerTimeoutStatus(true);
        handleCloseLogoModal();
        setShowAlert(true);
        setShowtext(t("GuestDashboard.timerTimeoutError"));
    }

    return (
        <>
            {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

            <ThankYouMessage
                isOpen={isThankYouModalOpen}
                setIsOpen={setIsThankYouModalOpen}
            />

            {/* <LogoModalLoader isOpenLogoModal={isLogoModalOpen} /> */}
            {isLogoModalOpen &&
                <CustomSpinner visible={isLogoModalOpen} timer={360} onTimerTimeout={onTimerTimeout}/>
            }

            <EmoduleTermsAndCondition
                withoutBureauModalOpen={withoutBureauModalOpen}
                setWithoutBureauModalOpen={setWithoutBureauModalOpen}
                subjectCheckboxShow={true}
                emailBoxButtonShow={true}
                emailLabel={t("guestTermsCondition.emailLabel")}
                dataCurpZero={DataSend}
                nbData={nbdata}
                onConfirmation={openThankYouModal}
                setEmailSentModalOpen={setEmailSentModalOpen}
                subsections_dicts={subsectionDicts}
                nss1Data={nss1Data}
                nssData={nssData}
                estadoData={estadoData}
                curpData={curpData}
                cname={cname}
                edad={edad}
                cbcId={cbcId}
            />

            <EmoduleWithBureauModal
                withBureauOpen={withBureauOpen}
                setWithBureauOpen={setWithBureauOpen}
                onConfirmation={openThankYouModal}
                tagLineShow={true}
                subjectCheckboxShow={true}
                emailBoxButtonShow={true}
                emailLabel={t("guestTermsCondition.emailLabelpin")}
                mobileLabel={t("guestTermsCondition.mobileLabel")}
                dataCurpZero={DataSend}
                nbData={nbdata}
                subsections_dicts={subsectionDicts}
                setEmailSentModalOpen={setEmailSentModalOpen}
                cbcId={cbcId}
            />

            <EmailSuccessfullySent
                isOpen={emailSentModalOpen}
                setEmailSentModalOpen={setEmailSentModalOpen}
                setWithoutBureauModalOpen={setWithoutBureauModalOpen}
            />

            <Modal
                open={companyModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            position: "fixed",
                            top: "16px",
                            right: "16px",
                            height: "100%",
                        }}
                    >
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Grid item xs={12} sm={12} sx={{ marginBottom: "6px" }}>
                        <Box sx={{
                            mt: { xs: 2, sm: 1 },
                            borderBottom: "1px solid rgba(112, 112, 112, 0.2)",
                            paddingBottom: "16px"
                        }}>
                            <Typography
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: { xs: "22px", sm: "24px" },
                                    lineHeight: "35px",
                                    textAlign: "center",
                                    color: "#1C212D",
                                }}
                            >
                                {t("Emodule.Choose Company Name")}
                            </Typography>
                            <Box sx={{ textAlign: "center", mt: -1 }}>
                                <img src={underLine} alt="under line" />
                            </Box>
                        </Box>
                    </Grid>
                    <List
                        sx={{
                            maxHeight: isMdOrLess
                                ? "40vh"
                                : isBetween1024And1440
                                    ? "40vh"
                                    : isGreaterThan1440
                                        ? "50vh"
                                        : "40vh", // Change the height value as needed7
                            overflowY: "auto",
                            paddingRight: "8px"
                        }}>
                        {companyListData?.length ? (
                            companyListData.map((company, index) => (
                                company.fme !== null ?
                                    (
                                        <ListItem
                                            key={index}
                                            sx={{
                                                cursor: "pointer",
                                                backgroundColor: selectedCompany.fme === company.fme ? "#FF8D28" : "rgba(65, 101, 246, 0.06)",
                                                border: selectedCompany.fme === company.fme ? "2px solid #FF8D28" : "2px solid transparent",
                                                marginBottom: "8px",
                                                borderRadius: "10px",
                                                paddingLeft: "16px",
                                                paddingRight: "16px"
                                            }}
                                            onClick={() => handleSelect(company)}
                                        >
                                            <ListItemText primary={`${company.razon_social}, ${company.region}`}
                                                sx={{
                                                    "& span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-54jg4i-MuiTypography-root": {
                                                        color: selectedCompany.fme === company.fme ? "#fff" : ""
                                                    }
                                                }}
                                            />
                                        </ListItem>
                                    ) :
                                    (
                                        <Typography
                                            sx={{
                                                textAlign: "center",
                                                fontSize: "22px",
                                                color: "#ee0505",
                                                fontFamily: "Gilroy-Medium",
                                                fontWeight: "500",
                                                marginTop: "10px",
                                                textTransform: "capitalize"

                                            }}>
                                            {t("Emodule.Companies not available")}
                                        </Typography>
                                    )
                            ))
                        ) : (
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontSize: "22px",
                                    color: "#ee0505",
                                    fontFamily: "Gilroy-Medium",
                                    fontWeight: "500"

                                }}>
                                {companyListData.error}
                            </Typography>
                        )}
                    </List>

                    {
                        selectedCompany.fme !== null && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    mt: 4,
                                }}
                            >
                                <Button
                                    disabled={!selectedCompany}
                                    sx={{
                                        backgroundColor: "primary.main",
                                        py: 1,
                                        px: { xs: 4, sm: 8 },
                                        fontSize: "18px",
                                        fontFamily: "Gilroy-Medium",
                                        textTransform: "capitalize",
                                    }}
                                    variant="contained"
                                    onClick={handleCompanyScraper}
                                >
                                    {t("buttons.buttonTextContinue")}
                                </Button>
                            </Box>
                        )
                    }

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            mt: 4,
                        }}
                    >
                        <Button
                            style={{
                                color: "rgba(27, 32, 44, 0.6)",
                                fontSize: 16,
                                fontFamily: "Gilroy-Medium",
                                textDecoration: "underline",
                                textTransform: "capitalize",
                            }}
                            variant="text"
                            onClick={handleClose}
                        >
                            {t("buttons.back")}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default CompanyListModal