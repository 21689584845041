import axios from "axios";
import { Box, Grid, Typography } from "@mui/material";
import { fontSize } from "@mui/system";
import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import lineImg from "../../assets/images/line-header-sec.png";
import { DataGrid } from "@mui/x-data-grid";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import DownloadIcon from "@mui/icons-material/Download";
import BlockIcon from "@mui/icons-material/Block";
import Cookies from "js-cookie";
import { encryptData } from "../../utils/encryptionHelper";
import { call_api_function } from "../../utils/verifyAPICalls";
import { serverRequest2 } from "../../utils/requestHelper";
import AlertModal from "../@common/Modal/AlertModal";
import AuthorizeModal from "../@common/Modal/AuthorizeModal";
import DeleteModal from "../@common/Modal/DeleteModal";
import DownloadAndDeactiveModal from "../@common/Modal/DownloadAndDeactiveModal";
import sjcl from "sjcl";
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import RenderCellExpander from "../@common/RenderCellExpander";
import consultPanel from "../../../src/assets/images/consultPanel.png";
import eModulePanel from "../../../src/assets/images/eModulePanel.png";
import generalIcon from "../../../src/assets/images/generalIcon.png";
import UserTypeModal from "../@common/Modal/UserTypeModal";



function RegisteredUserAction() {

  const { t } = useTranslation();
  const [userType, setUserType] = useState(null)
  const [userList, setUserList] = useState([]);
  const [adminUserList, setAdminUserList] = useState([]);
  const [cookieList, setCookieList] = useState([]);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [showAuthorizeModal, setAuthorizeModal] = useState(false); // Add a state to control the visibility of the AuthorizeModal
  const [selectedUserId, setSelectedUserId] = useState(null); // State to store the selected user's id
  const [selectedEmail, setSelectedEmail] = useState("");
  const [roleChange, setroleChange] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [userTypeModal, setUserTypeModal] = useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false); // Add a state to control the visibility of the DeleteModal
  const [status, setstatus] = useState(() => {
    // Check if there's a value in localStorage, use it if available
    const storedStatus = localStorage.getItem("status");
    return storedStatus ? JSON.parse(storedStatus) : false;
  });

  const [showsuccess, setsuccess] = useState(false);
  const [showModalDownload, setModalDownload] = useState(false);
  const [showDeactivate, setDeactivate] = useState(false);
  const handleModalDownload = () => {
    setModalDownload(false);
    setSelectedUserId(null);
    setDeactivate(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const handleCloseAlertAuthorizeModal = () => {
    setAuthorizeModal(false);
    setSelectedUserId(null);
    setroleChange(false);
  };
  const handleDeleteModal = () => {
    setDeleteModal(false);
    setSelectedUserId(null);
    setdeleteModal(false);
  };
  useEffect(() => {
    splitAndSetCookies();
  }, []);
  useEffect(() => {
    fetchUserList(cookieList);
  }, [cookieList]);
  useEffect(() => { }, [status]);

  const splitAndSetCookies = () => {
    const userInfoCookie = Cookies.get("userInfo");
    if (userInfoCookie != null && typeof userInfoCookie != "object") {
      setCookieList(userInfoCookie.split(","));
    }
  };

  const filterUserList = (data) => {
    const fullUsersList = data;
    const updatedEmailsAdmins = [];
    const updatedEmailsPendingAuth = [];
    const updatedEmails = [];
    const updatedEmailsNR = [];

    for (const user of fullUsersList) {
      const [email, isAdmin, role, removed] = user;
      if (isAdmin) {
        if (role == "admin") {
          updatedEmailsAdmins.push(user);
        } else if (!removed) {
          updatedEmailsPendingAuth.push(user);
        } else {
          updatedEmails.push(user);
        }
      } else if (isAdmin == "removed") {
        const index = fullUsersList.findIndex((user) => user[0] === email);
        if (index !== -1) {
          fullUsersList.splice(index, 1);
        }
      } else {
        updatedEmailsNR.push(user);
      }
    }

    return {
      fullUsersList,
      updatedEmailsAdmins,
      updatedEmailsPendingAuth,
      updatedEmails,
      updatedEmailsNR,
    };
  };

  const fetchUserList = async (cookList) => {
    if (cookList.length > 0 && cookList[3] != undefined) {
      const formData = new FormData();
      formData.append("0", cookList[3]);
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "perfil/lista_asignados",
        formData
      );
      if (response.data == "error bd" || response.data.lenght < 0) {
        setShowAlert(true);
        setShowtext(t("thanks.check"));
      } else if (response.data == "no data") {
        setShowAlert(true);
        setShowtext("No tienes,usuarios agregados");
      }
      const filteredUsers = filterUserList(response.data);
      // set differ users state when necessary
      setUserList(filteredUsers["fullUsersList"]);
      setAdminUserList(filteredUsers["updatedEmailsAdmins"]);
    }
  };
  const downloadUserActivity = async (id) => {
    try {
      if (id === null) {
        // Handle the case where id is not set
        setShowAlert(true);
        setShowtext("Select an email");
        return;
      }

      const data_eel = encryptData(cookieList[3], 500);
      const data_utr = encryptData(selectedEmail, 500);
      var dataForm = {
        data_eel: {
          data_eeli: data_eel["iv"],
          data_eels: data_eel["salt"],
          data_eelc: data_eel["ct"],
        },
        data_utr: {
          data_utri: data_utr["iv"],
          data_utrs: data_utr["salt"],
          data_utrc: data_utr["ct"],
        },
        token: cookieList[4],
      };
      const response = await serverRequest2("post", "user-activity", dataForm);
      if (response && response.data) {
        let csv = "activity_info,activity_type,day,timestamp,user\n";

        response.data.forEach(function (row) {
          csv +=
            row.activity_info +
            "," +
            row.activity_type +
            "," +
            row.timestamp +
            "," +
            row.user +
            "\n";
        });

        let blob = new Blob([csv], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "user-report.csv";

        // Trigger the download link
        a.click();
        handleModalDownload();
      } else {
        setShowAlert(true);
        setShowtext("No data found in the API response.");
      }
    } catch (error) {
      setShowAlert(true);
      setShowtext("An error occurred during the download:", error);
    }
  };

  const deactivateUser = async (id) => {
    if (id === null) {
      // Handle the case where id is not set
      setShowAlert(true);
      setShowtext("Select an email");
      return;
    }

    const data_eel = encryptData(cookieList[3], 500);
    const data_utu = encryptData(selectedEmail, 500);
    var dataForm = {
      data_eel: {
        data_eeli: data_eel["iv"],
        data_eels: data_eel["salt"],
        data_eelc: data_eel["ct"],
      },
      data_utu: {
        data_utui: data_utu["iv"],
        data_utus: data_utu["salt"],
        data_utuc: data_utu["ct"],
      },
      token: cookieList[4],
      status: status ? "true" : "false",
    };
    const response = await serverRequest2("post", "deactivate_user", dataForm);
    if (response && response.data) {
      handleModalDownload();
      setsuccess(true);
      const newStatus = !status;

      // Store the updated status in localStorage
      localStorage.setItem("status", JSON.stringify(newStatus));
      setstatus(!status);
      setShowAlert(true);
      setShowtext(response.data.message);
      return;
    }
  };

  const columns = [
    {
      field: "email",
      headerName: t("AdministratorUsers.rightContentTablesHeaders"),
      flex: 1,
      minWidth: 90,
      editable: true,
      renderCell: RenderCellExpander,
    },
    {
      field: "action",
      headerName: t("AdministratorUsers.Actions"),
      width: 230,
      headerAlign: "center",
      align: "center",

      //   editable: true,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" columnGap={1}>
          <Button
            onClick={() => handlechangeRoleToAdmin(params.row.id)}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >

            <Tooltip title={t("AdministratorUsers.tooltip1")} arrow placement="top">
              <StarIcon sx={{ color: "#FAD803" }} fontSize="small" />
            </Tooltip>
          </Button>
          <Button
            onClick={() => handlechangeUserType(params.row.id, params.row.type, "user")}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <Tooltip title={t("AdministratorUsers.tooltip9")} arrow placement="top" >
              <img src={(params.row.type === "Recruiter") ? consultPanel
                : (params.row.type === "E_Module") ? eModulePanel
                  : generalIcon}
                alt="userType image"
                style={{
                  width: "16px",
                  height: "16px"
                }} />
            </Tooltip>
          </Button>
          <Button
            onClick={() => handledownloadUserActivity(params.row.id)}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <Tooltip title={t("AdministratorUsers.tooltip2")} arrow placement="top" >
              <DownloadIcon sx={{ color: "#4165f6" }} fontSize="small" />
            </Tooltip>
          </Button>
          <Button
            onClick={() => handledeactivateUser(params.row.id)}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            {/* {status
                  ? t("AdministratorUsers.deactivate")
                  : t("AdministratorUsers.activate")}{" "}
                {" a este "}{" "} */}
            <Tooltip title={status
              ? t("AdministratorUsers.tooltip3Deactivate")
              : t("AdministratorUsers.tooltip3Activate")} arrow placement="top" >
              <BlockIcon sx={{ color: "#FE5A3E" }} fontSize="small" />
            </Tooltip>
          </Button>
          <Button
            onClick={() => handleOpenDeleteRegisterModal(params.row.id)}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <Tooltip title={t("AdministratorUsers.tooltip4")} arrow placement="top" >
              <DeleteIcon sx={{ color: "#FE5A3E" }} fontSize="small" />
            </Tooltip>
          </Button>
        </Stack>
      ),
    },
  ];

  const columnsAdmin = [
    {
      field: "email",
      headerName: t("AdministratorUsers.rightContentTablesHeaders"),
      flex: 1,
      minWidth: 90,
      editable: true,
      renderCell: RenderCellExpander,
    },
    {
      field: "action",
      headerName: t("AdministratorUsers.Actions"),
      width: 230,
      headerAlign: "center",
      align: "center",
      //   editable: true,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" columnGap={1}>
          <Button
            onClick={() => handlechangeAdminToUser(params.row.id)}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <Tooltip title={t("AdministratorUsers.tooltip5")} arrow placement="top" >
              <StarIcon sx={{ color: "#FAD803" }} fontSize="small" />
            </Tooltip>
          </Button>
          <Button
            onClick={() => handlechangeUserType(params.row.id, params.row.type, "admin")}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <Tooltip title={t("AdministratorUsers.tooltip9")} arrow placement="top" >
              <img src={(params.row.type === "Recruiter") ? consultPanel
                : (params.row.type === "E_Module") ? eModulePanel
                  : generalIcon}
                alt="userType image"
                style={{
                  width: "16px",
                  height: "16px"
                }} />
            </Tooltip>
          </Button>
          <Button
            onClick={() => handledownloadAdminUserActivity(params.row.id)}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <Tooltip title={t("AdministratorUsers.tooltip6")} arrow placement="top" >
              <DownloadIcon sx={{ color: "#4165f6" }} fontSize="small" />
            </Tooltip>

          </Button>
          <Button
            onClick={() => handledeactivateAdminUser(params.row.id)}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <Tooltip title={status
              ? t("AdministratorUsers.tooltip7Deactivate")
              : t("AdministratorUsers.tooltip7Activate")} arrow placement="top" >
              <BlockIcon sx={{ color: "#FE5A3E" }} fontSize="small" />
            </Tooltip>
          </Button>
          <Button
            onClick={() => handleOpenDeleteAdminUsers(params.row.id)}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <Tooltip title={t("AdministratorUsers.tooltip8")} arrow placement="top" >
              <DeleteIcon sx={{ color: "#FE5A3E" }} fontSize="small" />
            </Tooltip>
          </Button>
        </Stack>
      ),
    },
  ];
  const columnsTwo = [
    {
      field: "email",
      headerName: t("AdministratorUsers.rightContentTablesHeaders"),
      editable: true,
      flex: 1,
      renderCell: RenderCellExpander,
    },
    {
      field: "action",
      headerName: t("AdministratorUsers.Actions"),
      width: 180,

      renderCell: (params) => (
        <Stack direction="row" alignItems="center" columnGap={1}>
          <Button
            onClick={() => handleOpenAuthorizeModal(params.row.id)}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <DoneIcon sx={{ color: "#06A700" }} fontSize="small" />
          </Button>
          <Button
            onClick={() => handlechangeUserType(params.row.id, params.row.type, "pending")}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <Tooltip title={t("AdministratorUsers.tooltip9")} arrow placement="top" >
              <img src={(params.row.type === "Recruiter") ? consultPanel
                : (params.row.type === "E_Module") ? eModulePanel
                  : generalIcon}
                alt="userType image"
                style={{
                  width: "16px",
                  height: "16px"
                }} />
            </Tooltip>
          </Button>
          <Button
            onClick={() => handleOpenDeleteModal(params.row.id)}
            sx={{
              border: "solid 1px rgba(27, 32, 44, 0.2)",
              borderRadius: 50,
              p: "7px !important",
              height: "35px",
              width: "35px",
              minWidth: "35px",
            }}
          >
            <DeleteIcon sx={{ color: "#FE5A3E" }} fontSize="small" />
          </Button>
        </Stack>
      ),
    },
  ];
  const rows = [
    { id: 1, email: "erendoncc@gmail.com" },
    { id: 2, email: "morkfen@gmail.com" },
    { id: 3, email: "morkfen@gmail.com" },
  ];
  // Filter users based on role 'usuario'
  let rowsForRegisterUser = [];
  let rowsForPendingAuth = [];
  let rowsForAdminUsers = [];
  if (Array.isArray(userList)) {
    rowsForRegisterUser = userList
      .filter((user) => user[2] === "usuario" && user[3] === true)
      .map(([email, , , , , type], index) => ({ id: index, email, type }));

    rowsForPendingAuth = userList
      .filter((user) => user[3] === false)
      .map(([email, , , , , type], index) => ({ id: index, email, type }));
    rowsForAdminUsers = userList
      .filter((user) => user[1] === true && user[2] === "admin")
      .map(([email, , , , , type], index) => ({ id: index, email, type }));
  }

  const handlechangeRoleToAdmin = (id) => {
    setSelectedEmail(
      rowsForRegisterUser.find((userObj) => userObj.id === id).email
    );
    setSelectedUserId(id);
    setroleChange(true);
    setAuthorizeModal(true);
  };
  const handlechangeAdminToUser = (id) => {
    setSelectedEmail(
      rowsForAdminUsers.find((userObj) => userObj.id === id).email
    );
    setSelectedUserId(id);
    setroleChange(true);
    setAuthorizeModal(true);
  };
  const handledownloadAdminUserActivity = (id) => {
    setSelectedEmail(
      rowsForAdminUsers.find((userObj) => userObj.id === id).email
    );
    setSelectedUserId(id);
    setModalDownload(true);
  };
  const handledeactivateAdminUser = (id) => {
    setSelectedEmail(
      rowsForAdminUsers.find((userObj) => userObj.id === id).email
    );
    setSelectedUserId(id);
    setModalDownload(true);
    setDeactivate(true);
  };
  const handledeactivateUser = (id) => {
    setSelectedEmail(
      rowsForRegisterUser.find((userObj) => userObj.id === id).email
    );
    setSelectedUserId(id);
    setModalDownload(true);
    setDeactivate(true);
  };
  const handledownloadUserActivity = (id) => {
    setSelectedEmail(
      rowsForRegisterUser.find((userObj) => userObj.id === id).email
    );
    setSelectedUserId(id);
    setModalDownload(true);
  };
  const changeRoleToAdmin = async (id) => {
    if (id === null) {
      // Handle the case where id is not set
      setShowAlert(true);
      setShowtext("select an email");
      return;
    }
    const data_oid = encryptData(cookieList[6], 500);
    const data_uel = encryptData(selectedEmail, 500);
    var user_data = {
      data_oid: {
        data_oidi: data_oid["iv"],
        data_oids: data_oid["salt"],
        data_oidc: data_oid["ct"],
      },
      data_uel: {
        data_ueli: data_uel["iv"],
        data_uels: data_uel["salt"],
        data_uelc: data_uel["ct"],
      },
    };

    const response = await serverRequest2(
      "post",
      "perfil/cambiar_rol",
      user_data
    );

    if (response.data === "ok") {
      fetchUserList(cookieList);
      handleCloseAlertAuthorizeModal();
    } else {
      setShowAlert(true);
      setShowtext("error");
    }
  };
  const deleteUser = async (id) => {
    if (id === null) {
      // Handle the case where id is not set
      setShowAlert(true);
      setShowtext("select an email");
      return;
    }
    const mails = {
      data_eel: cookieList[3],
      data_eeo: selectedEmail,
    };
    const response = await serverRequest2(
      "post",
      "perfil/eliminar_usuario",
      mails
    );
    if (response.data) {
      fetchUserList(cookieList);
      handleDeleteModal();
    }
  };
  const handleOpenAuthorizeModal = (id) => {
    setSelectedEmail(
      rowsForPendingAuth.find((userObj) => userObj.id === id).email
    );
    setSelectedUserId(id);
    setAuthorizeModal(true);
  };
  const handleOpenDeleteModal = (id) => {
    setSelectedEmail(
      rowsForPendingAuth.find((userObj) => userObj.id === id).email
    );
    setSelectedUserId(id);
    setDeleteModal(true);
  };

  const handleOpenDeleteRegisterModal = (id) => {
    setSelectedEmail(
      rowsForRegisterUser.find((userObj) => userObj.id === id).email
    );
    setSelectedUserId(id);
    setDeleteModal(true);
    setdeleteModal(true);
  };
  const handleOpenDeleteAdminUsers = (id) => {
    setSelectedEmail(
      rowsForAdminUsers.find((userObj) => userObj.id === id).email
    );
    setSelectedUserId(id);
    setDeleteModal(true);
    setdeleteModal(true);
  };

  const handlechangeUserType = (id, type, flag) => {
    if (flag === "user") {
      setSelectedEmail(
        rowsForRegisterUser.find((userObj) => userObj.id === id).email
      );

    }
    else if (flag === "admin") {
      setSelectedEmail(
        rowsForAdminUsers.find((userObj) => userObj.id === id).email
      );

    }
    else {
      setSelectedEmail(
        rowsForPendingAuth.find((userObj) => userObj.id === id).email
      );
    }
    setUserType(type)
    setSelectedUserId(id);
    setUserTypeModal(true)
  }

  const handleButtonClick = async (id) => {
    if (selectedUserId === null) {
      // Handle the case where selectedUserId is not set
      setShowAlert(true);
      setShowtext("select an email");
      return;
    }
    // Encrypt data for the specific user
    const data_oid = encryptData(cookieList[6], 500);
    const data_uel = encryptData(
      rowsForPendingAuth.find((userObj) => userObj.id === id).email,
      500
    );

    var user_data = {
      data_oid: {
        data_oidi: data_oid["iv"],
        data_oids: data_oid["salt"],
        data_oidc: data_oid["ct"],
      },
      data_uel: {
        data_ueli: data_uel["iv"],
        data_uels: data_uel["salt"],
        data_uelc: data_uel["ct"],
      },
    };

    // Send encrypted data to the server
    const response = await serverRequest2(
      "post",
      "perfil/autorizar_cuenta",
      user_data
    );

    if (response.data === "ok") {
      fetchUserList(cookieList);
      handleCloseAlertAuthorizeModal();
    } else {
      setShowAlert(true);
      setShowtext("error");
    }
  };

  const deletePendingUser = async (id) => {
    const userToDelete = rowsForPendingAuth.find(
      (userObj) => userObj.id === id
    );
    if (!userToDelete) {
      return;
    }

    const mails = {
      data_eel: cookieList[3],
      data_eeo: selectedEmail,
    };
    const response = await serverRequest2(
      "post",
      "perfil/eliminar_usuario",
      mails
    );

    if (response.data) {
      fetchUserList(cookieList);
      handleDeleteModal();
    }
  };

  const continueHandler = () => {
    alert("working fine");
  };

  return (
    <>
      {showAuthorizeModal && (
        <AuthorizeModal
          handleclosed={handleCloseAlertAuthorizeModal}
          onAccept={handleButtonClick}
          userId={selectedUserId}
          selectedEmail={selectedEmail}
          changeRoleToAdmin={changeRoleToAdmin}
          roleChange={roleChange}
        />
      )}
      {showAlert && (
        <AlertModal
          text={showtext}
          onClose={handleCloseAlert}
          thanks={showsuccess}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          handleclosed={handleDeleteModal}
          deleteUser={deleteUser}
          deleteModal={deleteModal}
          deletePendingUser={deletePendingUser}
          userId={selectedUserId}
          selectedEmail={selectedEmail}
        />
      )}
      {showModalDownload && (
        <DownloadAndDeactiveModal
          status={status}
          showDeactivate={showDeactivate}
          handleclosed={handleModalDownload}
          userId={selectedUserId}
          selectedEmail={selectedEmail}
          downloadUserActivity={downloadUserActivity}
          deactivateUser={deactivateUser}
        />
      )}

      <UserTypeModal
        userTypeModal={userTypeModal}
        setUserTypeModal={setUserTypeModal}
        userEmail={selectedEmail}
        userType={userType}
      />

      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: 2,
          px: { xs: 2, sm: 2, lg: 4 },
          py: 3,
        }}
      >
        <Grid container spacing={1}>
          {rowsForAdminUsers.length > 0 && (
            <Grid item xs={12} lg={6}>
              <Box>
                <Typography
                  sx={{
                    color: "neutral.900",
                    fontFamily: "Gilroy-SemiBold",
                    fontSize: 22,
                    textAlign: "center",
                  }}
                >
                  {t("AdministratorUsers.rightContentTittle1")}
                </Typography>
                <Box sx={{ width: "100%", mt: 1.8 }}>
                  <DataGrid
                    rows={rowsForAdminUsers}
                    columns={columnsAdmin}
                    isCellEditable={() => false}
                    autoHeight
                    sx={{
                      border: "solid 1px #f4f4f4",
                      width: "100%",

                      "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderTopRightRadius: 4,
                        width: "100% !important",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontSize: 16,
                        color: "neutral.900",
                        fontFamily: "Gilroy-SemiBold",
                      },
                      "& .MuiDataGrid-cellContent": {
                        fontSize: 16,
                        color: "neutral.900",
                        fontFamily: "Gilroy-Medium",
                      },
                      "& .css-wop1k0-MuiDataGrid-footerContainer": {
                        display: "none",
                      },
                      "& .MuiDataGrid-withBorderColor": {
                        borderColor: "#f4f4f4 !important",
                      },
                      "&.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor":
                      {
                        marginLeft: "100px !important",
                      },
                      "& .MuiDataGrid-columnHeadersInner": {
                        width: "100% !important",
                      },
                      "& .MuiDataGrid-selectedRowCount": {
                        display: "none !important",
                      },
                    }}
                    initialState={{
                      pagination: false, // Disable pagination
                    }}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                    hideFooterPagination={true}
                    disableColumnMenu={true} // disable the filter functionality
                  />
                </Box>
              </Box>
            </Grid>
          )}
          {rowsForRegisterUser.length > 0 && (
            <Grid item xs={12} lg={6}>
              <Box>
                <Typography
                  sx={{
                    color: "neutral.900",
                    fontFamily: "Gilroy-SemiBold",
                    fontSize: 22,
                    textAlign: "center",
                  }}
                >
                  {t("AdministratorUsers.rightContentTittle2")}
                </Typography>
                <Box sx={{ width: "100%", mt: 1.8 }}>
                  <DataGrid
                    rows={rowsForRegisterUser}
                    columns={columns}
                    isCellEditable={() => false}
                    autoHeight
                    sx={{
                      border: "solid 1px #f4f4f4",
                      width: "100%",
                      "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderTopRightRadius: 4,
                        width: "100% !important",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontSize: 18,
                        color: "neutral.900",
                        fontFamily: "Gilroy-SemiBold",
                      },
                      "& .MuiDataGrid-cellContent": {
                        fontSize: 18,
                        color: "neutral.900",
                        fontFamily: "Gilroy-Medium",
                      },
                      "& .css-wop1k0-MuiDataGrid-footerContainer": {
                        display: "none",
                      },
                      "& .MuiDataGrid-withBorderColor": {
                        borderColor: "#f4f4f4 !important",
                      },
                      "& .MuiDataGrid-columnHeadersInner": {
                        width: "100% !important",
                      },
                      "& .MuiDataGrid-selectedRowCount": {
                        display: "none !important",
                      },
                    }}
                    initialState={{
                      pagination: false, // Disable pagination
                    }}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                    hideFooterPagination={true}
                    disableColumnMenu={true} // disable the filter functionality
                  />
                </Box>
              </Box>
            </Grid>
          )}

          {rowsForPendingAuth.length > 0 && (
            <Grid item xs={12} lg={6}>
              <Box>
                <Typography
                  sx={{
                    color: "neutral.900",
                    fontFamily: "Gilroy-SemiBold",
                    fontSize: 22,
                    textAlign: "center",
                  }}
                >
                  {t("AdministratorUsers.rightContentTittle3")}
                </Typography>
                <Box sx={{ width: "100%", mt: 2 }}>
                  <DataGrid
                    rows={rowsForPendingAuth}
                    columns={columnsTwo}
                    isCellEditable={() => false}
                    autoHeight
                    sx={{
                      border: "solid 1px #f4f4f4",
                      "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderTopRightRadius: 4,
                        width: "100%",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontSize: 18,
                        color: "neutral.900",
                        fontFamily: "Gilroy-SemiBold",
                      },
                      "& .MuiDataGrid-cellContent": {
                        fontSize: 18,
                        color: "neutral.900",
                        fontFamily: "Gilroy-Medium",
                      },
                      "& .css-wop1k0-MuiDataGrid-footerContainer": {
                        display: "none",
                      },
                      "& .MuiDataGrid-columnHeadersInner": {
                        width: "100% !important",
                      },
                      "& .MuiDataGrid-selectedRowCount": {
                        display: "none !important",
                      },
                    }}
                    initialState={{
                      pagination: false, // Disable pagination
                    }}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                    hideFooterPagination={true}
                    disableColumnMenu={true} // disable the filter functionality
                  />
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>

        {/* <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
              {rowsForPendingAuth.length > 0 ? (
                <div>
                  <Button
                    disabled={rowsForPendingAuth.length === 0}
                    onClick={continueHandler}
                    variant="contained"
                    sx={{
                      backgroundColor: "primary.main",
                      py: 1,
                      px: { xs: 4, sm: 10 },
                      fontSize: "18px",
                      fontFamily: "Gilroy-Medium",
                      textTransform: "capitalize",
                    }}
                  >
                    Add
                  </Button>
                </div>
              ) : // Render null if rowsForPendingAuth does not exist
              null}
            </Box> */}
      </Box>
    </>
  );
}

export default RegisteredUserAction;
