import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import authimage from "../../../assets/images/authorize-user.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import privacyImg from "../../../assets/images/resend-privacy.svg";
import privacyImggrey from "../../../assets/images/svgviewer-output.svg";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Authorizeimage from "../../../assets/images/Authorization Icon.svg";
import reportimg from "../../../assets/images/reporticonblue.svg";
import ModalUpdatereport from "./ModalUpdatereport";
import { useTranslation } from "react-i18next";
function LightBlackComponent({
  vistosReporte,
  clickLocation,
  updateReport,
  curp,
  nombre,
  email,
  handleResendCandidateClosed,
  itemId,
  showAllResendIcon,
  forwardEmail,
  eForwardEmail,
  itemIndex,
  showReport,
  showEReport,
  resendReport,
  resendEReport,
  downloadReport,
  downloadEReport,
  showWhiteCircle,
  moduleType
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelReport, setisModelReport] = useState(false);
  const [isModelDownload, setisModelDownload] = useState(false);
  const [isModelEmail, setisModelEmail] = useState(false);
  const handleShowReport = async () => {
    await showReport(itemId, curp, email, false);
  };
  const handleShowEReport = async () => {
    await showEReport(itemId, nombre, email, false);
  };
  const handleDownloadReport = async () => {
    await downloadReport(itemId, curp, email, false);
  };
  const handleDownloadEReport = async () => {
    await downloadEReport(itemId, nombre, email, false);
  };
  const handleforwardEmail = async () => {
    await resendReport(itemId, curp, email, false);
  };
  const handleEForwardEmail = async () => {
    await resendEReport(itemId, nombre, email, false);
  };
  const handleclosed = () => {
    setIsModalOpen(false);
    setisModelEmail(false);
    setisModelDownload(false);
    setisModelReport(false);
  };

  return (
    <div>
      {isModalOpen && (
        <ModalUpdatereport
          resendReport={resendReport}
          vistosReporte={vistosReporte}
          curp={curp}
          email={email}
          isModelDownload={isModelDownload}
          isModelEmail={isModelEmail}
          updateReport={updateReport}
          itemId={itemId}
          handleclosed={handleclosed}
          handleResendCandidateClosed={handleResendCandidateClosed}
          showReport={showReport}
          downloadReport={downloadReport}
          forwardEmail={forwardEmail}
          eForwardEmail={eForwardEmail}
          itemIndex={itemIndex}
          isModelReport={isModelReport}
        />
      )}
      <Modal
        open={true}
        onClose={handleResendCandidateClosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {showAllResendIcon ? (
          <div>
            {" "}
            <div>
              <div
                style={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "block",
                    backgroundColor: "white", // White with 50% transparency
                    fontSize: 14,
                    fontFamily: "Gilroy-Medium",
                    width: "45px",
                    height: "45px",
                    borderRadius: "50%",
                  }}
                >
                  {" "}
                  <img
                    src={reportimg}
                    width={"24px"}
                    alt="icon email send"
                    style={{
                      paddingTop: "10px",
                      marginLeft: "14px",
                      display: "block",
                    }}
                  />
                </Typography>
              </div>

              <div
                style={{
                  backgroundColor: isModelDownload ? "#FF8D28" : "#4165f6",
                  width: isModelDownload ? "55px" : "50px",
                  height: isModelDownload ? "55px" : "50px",
                  borderRadius: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-80px",
                  "&:hover": {
                    backgroundColor: "#FF8D28",
                  },
                }}
              >
                <IconButton
                  onClick={() => {
                    if (moduleType === "candidato") {
                      handleDownloadReport();
                    } else {
                      handleDownloadEReport();
                    }
                  }}
                  disabled={vistosReporte === 0}
                >
                  <Box
                    sx={{
                      p: "7px",
                      height: "40px",
                      width: "40px",
                      mx: "auto",
                      display: "block",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SimCardDownloadIcon
                      sx={{ color: vistosReporte === 0 ? "gray" : "#ffffff" }}
                    />
                  </Box>
                </IconButton>
              </div>

              <div
                style={{
                  backgroundColor: isModelReport ? "#FF8D28" : "#4165f6",
                  width: isModelReport ? "55px" : "50px",
                  height: isModelReport ? "55px" : "50px",
                  borderRadius: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-30px",
                  marginLeft: "-80px",
                  "&:hover": {
                    backgroundColor: "#FF8D28",
                  },
                }}
              >
                <IconButton onClick={() => {
                  if (moduleType === "candidato") {
                    handleShowReport();
                  } else if (moduleType === "empresa") {
                    handleShowEReport();
                  }
                }}>
                  <Box
                    sx={{
                      p: "7px",
                      height: "40px",
                      width: "40px",
                      mx: "auto",
                      display: "block",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <VisibilityIcon sx={{ color: "#ffffff" }} />
                  </Box>
                </IconButton>
              </div>

              <div
                style={{
                  backgroundColor: isModelEmail ? "#FF8D28" : "#4165f6",
                  width: isModelEmail ? "55px" : "50px",
                  height: isModelEmail ? "55px" : "50px",
                  borderRadius: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-30px",
                  marginLeft: "80px",
                  "&:hover": {
                    backgroundColor: "#FF8D28",
                  },
                }}
              >
                <IconButton
                  onClick={() => {
                    if (moduleType === "candidato") {
                      handleforwardEmail();
                    } else if (moduleType === "empresa") {
                      handleEForwardEmail();
                    }
                  }}
                  disabled={vistosReporte === 0}
                >
                  <Box
                    sx={{
                      p: "7px",
                      height: "40px",
                      width: "40px",
                      mx: "auto",
                      display: "block",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {vistosReporte === 0 ? (
                      <img
                        src={privacyImggrey}
                        width={"26px"}
                        alt="icon email send"
                      />
                    ) : (
                      <img
                        src={privacyImg}
                        width={"26px"}
                        alt="icon email send"
                      />
                    )}
                  </Box>
                </IconButton>
              </div>


              <Typography
                sx={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-120px",
                  color: "#ffffff",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {t("authorizationpanel.rightContent.buttons.Download")}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-70px",
                  marginLeft: "-80px",
                  color: "#ffffff",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {t("authorizationpanel.rightContent.buttons.View")}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-70px",
                  marginLeft: "80px",
                  color: "#ffffff",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {t("authorizationpanel.rightContent.buttons.Send")}
              </Typography>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                position: "absolute",
                top: clickLocation.y,
                left: clickLocation.x,
                transform: "translate(-50%, -50%)",
                marginLeft: "5px",
                marginTop: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "block",
                  backgroundColor: "white", // White with 50% transparency
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                  width: "45px",
                  height: "45px",
                  borderRadius: "50%",
                }}
              >
                {" "}
                <img
                  src={Authorizeimage}
                  width={"29px"}
                  alt="icon email send"
                  style={{
                    filter: "grayscale(100%)",
                    marginTop: "5px",
                    marginLeft: "7px",
                  }}
                />
              </Typography>
            </div>

            <div
              style={{
                backgroundColor: "#4165f6",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: clickLocation.y,
                left: clickLocation.x,
                transform: "translate(-50%, -50%)",
                marginTop: "-80px",
                "&:hover": {
                  backgroundColor: "#FF8D28",
                },
              }}
            >
              <IconButton onClick={() => {
                if (moduleType === "candidato") {
                  forwardEmail(itemId, itemIndex)
                } else if (moduleType === "empresa") {
                  eForwardEmail(itemId, itemIndex)
                }
              }}>
                <Box
                  sx={{
                    p: "7px",
                    height: "40px",
                    width: "40px",
                    mx: "auto",
                    display: "block",
                    marginLeft: "3px",
                  }}
                >
                  <SendIcon
                    sx={{ color: "#ffffff", transform: "rotate(-24deg)" }}
                  />
                </Box>
              </IconButton>
            </div>
            <Typography
              sx={{
                position: "absolute",
                top: clickLocation.y,
                left: clickLocation.x,
                transform: "translate(-50%, -50%)",
                marginTop: "-120px",
                color: "#ffffff",
                fontSize: 14,
                fontFamily: "Gilroy-Medium",
                marginLeft: { xs: "20px" },
              }}
            >
              {t("authorizationpanel.rightContent.buttons.Authorization")}
            </Typography>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default LightBlackComponent;
