import React from "react";
import "./LabelInput.css";

function LabelInput(props) {
  const {
    label,
    inputMode,
    type = "text",
    inputValue,
    placeholder,
    name = "name",
    onChange,
    onKeyUp, // Add onKeyUp event handler
    errorMsg = "errorMsg",
    flag = false,
    containerStyle,
    readOnly = false,
    maxLength,
    disabled,
    inputStyle,
    inputContainerStyle,
    id = "input",
    star,
    subLabel,
    autofill,
    Mandatory,
    isValid = true,
    emoduleInput
  } = props;

  return (
    <div className="li_container" style={containerStyle}>
      {label ? (
        <>
          <label htmlFor={id} className="li_label"> {/* Add htmlFor attribute */}
            {label}
            <span
              style={{
                color: "rgba(27, 32, 44, 0.6)",
                fontSize: "12px",
                marginLeft: "6px",
              }}
            >
              {subLabel}
            </span>
          </label>
        </>
      ) : null}
      <div className="li_input_container" style={inputContainerStyle}>
        <div
          className="container_box"
          style={{
            backgroundColor: disabled ? "rgba(100,100,100,0.5)" : emoduleInput
              ?
              (Mandatory ? (inputValue ? "rgba(65, 101, 246, 0.06)" : "rgba(255,140, 0, 0.1) ") : "rgba(65, 101, 246, 0.06)")
              : !isValid ? 'rgba(255,140, 0, 0.1)' : inputValue ? "rgba(65, 101, 246, 0.06)" : Mandatory ? 'rgba(255,140, 0, 0.1)' : inputValue === "" && "#DFFAFE"
          }}
        >
          <input
            id={id}
            value={inputValue}
            className="li_input"
            style={inputStyle}
            type={type}
            placeholder={placeholder}
            name={name}
            onChange={(e) => {
              if (type === "number") {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }

              if (maxLength && e.target.value.length > maxLength) {
                e.target.value = e.target.value.slice(0, maxLength);
              }

              onChange(e);
            }}
            onKeyUp={onKeyUp} // Attach the onKeyUp event handler
            readOnly={readOnly}
            maxLength={maxLength}
            disabled={disabled}
            inputMode={inputMode}
          />
        </div>
        <p className="li_error_text" style={{ display: flag ? "block" : "none" }}>
          {errorMsg}
        </p>
      </div>
    </div>
  );
}

export default LabelInput;
