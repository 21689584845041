export const sectionAData = {
    I: {
        titlealphabet: "A",
        title: "Emodule.A | GENERALES.A. GENERALES.I",
        concepto:
            "Emodule.A | GENERALES.Concept.I",
        resultado: [
            "Emodule.A | GENERALES.Result.I"
        ],
        inner_table: {
            status: false,
            data: "", 
            type: "",
        },
        sub: "sub_a1",
        format: "FME"
    },
    II: {
        title: "Emodule.A | GENERALES.A. GENERALES.II",
        concepto:
            "Emodule.A | GENERALES.Concept.II",
        resultado: [
            "Emodule.A | GENERALES.Result.II"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_a2",
        parent_key: "siger",
        format: "GIRO"

    },
    III: {
        title: "Emodule.A | GENERALES.A. GENERALES.III",
        concepto:
            "Emodule.A | GENERALES.Concept.III",
        resultado: [
            "Emodule.A | GENERALES.Result.III"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_a3",
        parent_key: "siger",
        format: "OBJETO SOCIAL"
    },
    IV: {
        title: "Emodule.A | GENERALES.A. GENERALES.IV",
        concepto:
            "Emodule.A | GENERALES.Concept.IV",
        resultado: [
            "Emodule.A | GENERALES.Result.IV"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_a4",
        parent_key: "siger",
        format: "REGIMEN JURIDICO"
    },
    V: {
        title: "Emodule.A | GENERALES.A. GENERALES.V",
        concepto:
            "Emodule.A | GENERALES.Concept.V",
        resultado: [
            "Emodule.A | GENERALES.Result.V"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_a5",
        parent_key: "siger",
        format: "ANTECEDENTE REGISTRAL"
    },
    VI: {
        title: "Emodule.A | GENERALES.A. GENERALES.VI",
        concepto:
            "Emodule.A | GENERALES.Concept.VI",
        resultado: [
            "Emodule.A | GENERALES.Result.VI"
        ],
        inner_table: {
            status: false,
            type: "Repse",
        },
        sub: "sub_a6",
        format: "repse"
    },
    VII: {
        title: "Emodule.A | GENERALES.A. GENERALES.VII",
        concepto:
            "Emodule.A | GENERALES.Concept.VII",
        resultado: [
            "Emodule.A | GENERALES.Result.VII"
        ],
        inner_table: {
            status: true,
            parent_key: "repse",
            data: "servicios",
            type: "RepseService",
        },
        sub: "sub_a7",
    },
    VIII: {
        title: "Emodule.A | GENERALES.A. GENERALES.VIII",
        concepto:
            "Emodule.A | GENERALES.Concept.VIII",
        resultado: [
            "Emodule.A | GENERALES.Result.VIII"
        ],
        inner_table: {
            status: false,
            parent_key: "",
            data: "",
            type: "",
        },
        sub: "sub_a8",
        format: "rupc"
    }
};

export const sectionBData = {
    I: {
        titlealphabet: "B",
        title: "Emodule.B | Partner.B.partner.I",
        concepto:
            "Emodule.B | Partner.Concept.I",
        resultado: [
            "Emodule.B | Partner.Result.I",
        ],
        inner_table: {
            status: true,
            parent_key: "siger",
            data: "SOCIOS",
            type: "Members",
        },
        sub: "sub_b1",
    },
    II: {
        titlealphabet: "B",
        title: "Emodule.B | Partner.B.partner.II",
        concepto:
            "Emodule.B | Partner.Concept.II",
        resultado: [
            "Emodule.B | Partner.Result.II",
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_b2",
        parent_key: "condusef",
        child_key: "funcionarios",
        format: "CONSEJO DE ADMINISTRACIÓN"
    },
    III: {
        titlealphabet: "B",
        title: "Emodule.B | Partner.B.partner.III",
        concepto:
            "Emodule.B | Partner.Concept.III",
        resultado: [
            "Emodule.B | Partner.Result.III",
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_b3",
        parent_key: "condusef",
        child_key: "funcionarios",
        format: "FUNCIONARIOS"
    }
};


export const sectionCData = {
    I: {
        titlealphabet: "C",
        title: "Emodule.C | Company.C.Company.I",
        concepto:
            "Emodule.C | Company.Concept.I",
        resultado: [
            "Emodule.C | Company.Result.I"
        ],
        inner_table: {
            status: true,
            parent_key: "lawsuits",
            data: "Demandas_Laborales",
            type: "Labor",
        },
        sub: "sub_c1",
    },
    II: {
        titlealphabet: "C",
        title: "Emodule.C | Company.C.Company.II",
        concepto:
            "Emodule.C | Company.Concept.II",
        resultado: [
            "Emodule.C | Company.Result.II"
        ],
        inner_table: {
            status: true,
            parent_key: "lawsuits",
            data: "Demandas_Civiles",
            type: "Labor",
        },
        sub: "sub_c2",
    },
    III: {
        titlealphabet: "C",
        title: "Emodule.C | Company.C.Company.III",
        concepto:
            "Emodule.C | Company.Concept.III",
        resultado: [
            "Emodule.C | Company.Result.III"
        ],
        inner_table: {
            status: true,
            parent_key: "lawsuits",
            data: "Demandas_Penales",
            type: "Labor",
        },
        sub: "sub_c3",
    },
    IV: {
        titlealphabet: "C",
        title: "Emodule.C | Company.C.Company.IV",
        concepto:
            "Emodule.C | Company.Concept.IV",
        resultado: [
            "Emodule.C | Company.Result.IV"
        ],
        inner_table: {
            status: true,
            parent_key: "lawsuits",
            data: "Demandas_Administrativas",
            type: "Labor",
        },
        sub: "sub_c4",
    },
    V: {
        title: "Emodule.C | Company.C.Company.V",
        concepto:
            "Emodule.C | Company.Concept.V",
        resultado: [
            "Emodule.C | Company.Result.V"
        ],
        inner_table: {
            status: true,
            data: "sat_canceled",
            type: "CanceledSAT",
        },
        sub: "sub_c5",
    },
    VI: {
        title: "Emodule.C | Company.C.Company.VI",
        concepto:
            "Emodule.C | Company.Concept.VI",
        resultado: [
            "Emodule.C | Company.Result.VI"
        ],
        inner_table: {
            status: true,
            data: "sat_not_located",
            type: "NotLocated",
        },
        sub: "sub_c6",
    },
    VII: {
        title: "Emodule.C | Company.C.Company.VII",
        concepto:
            "Emodule.C | Company.Concept.VII",
        resultado: [
            "Emodule.C | Company.Result.VII"
        ],
        inner_table: {
            status: true,
            data: "sat_69_B",
            type: "SATB",
        },
        sub: "sub_c7",
    },
    VIII: {
        titlealphabet: "C",
        title: "Emodule.C | Company.C.Company.VIII",
        concepto:
            "Emodule.C | Company.Concept.VIII",
        resultado: [
            "Emodule.C | Company.Result.VIII"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_c8",
        parent_key: "pyvs",
        format: "empresa"

    },
    IX: {
        titlealphabet: "C",
        title: "Emodule.C | Company.C.Company.IX",
        concepto:
            "Emodule.C | Company.Concept.IX",
        resultado: [
            "Emodule.C | Company.Result.IX"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_c9",
        format: "alertas_internacionales_company"
    },
    X: {
        titlealphabet: "C",
        title: "Emodule.C | Company.C.Company.X",
        concepto:
            "Emodule.C | Company.Concept.X",
        resultado: [
            "Emodule.C | Company.Result.X"
        ],
        inner_table: {
            status: true,
            data: "profeco",
            type: "Profeco",
        },
        sub: "sub_c10",
    },
    XI: {
        titlealphabet: "C",
        title: "Emodule.C | Company.C.Company.XI",
        concepto:
            "Emodule.C | Company.Concept.XI",
        resultado: [
            "Emodule.C | Company.Result.XI"
        ],
        inner_table: {
            status: true,
            data: "licitaciones",
            type: "Licitaciones",
        },
        sub: "sub_c11",
    },
    XII: {
        titlealphabet: "C",
        title: "Emodule.C | Company.C.Company.XII",
        concepto:
            "Emodule.C | Company.Concept.XII",
        resultado: [
            "Emodule.C | Company.Result.XII"
        ],
        inner_table: {
            status: true,
            parent_key: "bdc",
            data: "mensajes",
            type: "Mensajes",
        },
        sub: "sub_c12",
    },
    XIII: {
        titlealphabet: "C",
        title: "Emodule.C | Company.C.Company.XIII",
        concepto:
            "Emodule.C | Company.Concept.XIII",
        resultado: [
            "Emodule.C | Company.Result.XIII"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_c13",
        parent_key: "siger",
        format: "ACTIVIDADES VULNERABLES"
    }
};

export const sectionDData = {
    I: {
        titlealphabet: "D",
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.I",
        concepto:
            "Emodule.D | PartnerLegal.Concept.I",
        resultado: [
            "Emodule.D | PartnerLegal.Result.I"
        ],
        inner_table: {
            status: true,
            parent_key: "lawsuits",
            data: "Demandas_Laborales",
            type: "Labor",
        },
        sub: "sub_d1",
    },
    II: {
        titlealphabet: "D",
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.II",
        concepto:
            "Emodule.D | PartnerLegal.Concept.II",
        resultado: [
            "Emodule.D | PartnerLegal.Result.II"
        ],
        inner_table: {
            status: true,
            parent_key: "lawsuits",
            data: "Demandas_Civiles",
            type: "Labor",
        },
        sub: "sub_d2",
    },
    III: {
        titlealphabet: "D",
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.III",
        concepto:
            "Emodule.D | PartnerLegal.Concept.III",
        resultado: [
            "Emodule.D | PartnerLegal.Result.III"
        ],
        inner_table: {
            status: true,
            parent_key: "lawsuits",
            data: "Demandas_Familiares",
            type: "Labor",
        },
        sub: "sub_d3",
    },
    IV: {
        titlealphabet: "D",
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.IV",
        concepto:
            "Emodule.D | PartnerLegal.Concept.IV",
        resultado: [
            "Emodule.D | PartnerLegal.Result.IV"
        ],
        inner_table: {
            status: true,
            parent_key: "lawsuits",
            data: "Demandas_Penales",
            type: "Labor",
        },
        sub: "sub_d4",
    },
    V: {
        titlealphabet: "D",
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.V",
        concepto:
            "Emodule.D | PartnerLegal.Concept.V",
        resultado: [
            "Emodule.D | PartnerLegal.Result.V"
        ],
        inner_table: {
            status: true,
            parent_key: "lawsuits",
            data: "Demandas_Administrativas",
            type: "Labor",
        },
        sub: "sub_d5",
    },
    VI: {
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.VI",
        concepto:
            "Emodule.D | PartnerLegal.Concept.VI",
        resultado: [
            "Emodule.D | PartnerLegal.Result.VI"
        ],
        inner_table: {
            status: true,
            data: "sat_canceled_socios",
            type: "CanceledSAT",
        },
        sub: "sub_d6",
    },
    VII: {
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.VII",
        concepto:
            "Emodule.D | PartnerLegal.Concept.VII",
        resultado: [
            "Emodule.D | PartnerLegal.Result.VII"
        ],
        inner_table: {
            status: true,
            data: "sat_not_located_socios",
            type: "NotLocated",
        },
        sub: "sub_d7",
    },
    VIII: {
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.VIII",
        concepto:
            "Emodule.D | PartnerLegal.Concept.VIII",
        resultado: [
            "Emodule.D | PartnerLegal.Result.VIII"
        ],
        inner_table: {
            status: true,
            data: "sat_69_B_socios",
            type: "SATB",
        },
        sub: "sub_d8",
    },
    IX: {
        titlealphabet: "D",
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.IX",
        concepto:
            "Emodule.D | PartnerLegal.Concept.IX",
        resultado: [
            "Emodule.D | PartnerLegal.Result.IX"
        ],
        inner_table: {
            status: true,
            parent_key: "pyvs",
            data: "partners",
            type: "Pycs",
        },
        sub: "sub_d9",
    },
    X: {
        titlealphabet: "D",
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.X",
        concepto:
            "Emodule.D | PartnerLegal.Concept.X",
        resultado: [
            "Emodule.D | PartnerLegal.Result.X"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_d10",
        format: "alertas_internacionales"
    },
    XI: {
        titlealphabet: "D",
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.XI",
        concepto:
            "Emodule.D | PartnerLegal.Concept.XI",
        resultado: [
            "Emodule.D | PartnerLegal.Result.XI"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_d11",
        format: "agresores_sexuales"
    },
    XII: {
        titlealphabet: "D",
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.XII",
        concepto:
            "Emodule.D | PartnerLegal.Concept.XII",
        resultado: [
            "Emodule.D | PartnerLegal.Result.XII"
        ],
        inner_table: {
            status: true,
            data: "pep",
            type: "",
        },
        sub: "sub_d12",
        format: "pep_details"
    },
    XIII: {
        titlealphabet: "D",
        title: "Emodule.D | PartnerLegal.D.PartnerLegal.XIII",
        concepto:
            "Emodule.D | PartnerLegal.Concept.XIII",
        resultado: [
            "Emodule.D | PartnerLegal.Result.XIII"
        ],
        inner_table: {
            status: true,
            data: "servidores_sancionados",
            type: "Servidores",
        },
        sub: "sub_d13",
    }
};

export const sectionEData = {
    I: {
        titlealphabet: "E",
        title: "Emodule.E | Credit.E.Credit.I",
        concepto:
            "Emodule.E | Credit.Concept.I",
        resultado: [
            "Emodule.E | Credit.Result.I"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_e1",
        parent_key: "bdc",
        format: "Tiempo_Creditos"
    },
    II: {
        titlealphabet: "E",
        title: "Emodule.E | Credit.E.Credit.II",
        concepto:
            "Emodule.E | Credit.Concept.II",
        resultado: [
            "Emodule.E | Credit.Result.II"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_e2",
        parent_key: "bdc",
        format: "Numero_creditos_total"
    },
    III: {
        titlealphabet: "E",
        title: "Emodule.E | Credit.E.Credit.III",
        concepto:
            "Emodule.E | Credit.Concept.III",
        resultado: [
            "Emodule.E | Credit.Result.III"
        ],
        inner_table: {
            status: true,
            parent_key: "bdc",
            data: "Tipo_Deuda",
            type: "",
        },
        sub: "sub_e3",
        parent_key: "bdc",
        format: "Tipo_Deuda_Details"
    },
    IV: {
        titlealphabet: "E",
        title: "Emodule.E | Credit.E.Credit.IV",
        concepto:
            "Emodule.E | Credit.Concept.IV",
        resultado: [
            "Emodule.E | Credit.Result.IV"
        ],
        inner_table: {
            status: true,
            parent_key: "bdc",
            data: "Instituciones",
            // type: "institution",
        },
        sub: "sub_e4",
        parent_key: "bdc",
        format: "Instituciones_len"
    },
    V: {
        titlealphabet: "E",
        title: "Emodule.E | Credit.E.Credit.V",
        concepto:
            "Emodule.E | Credit.Concept.V",
        resultado: [
            "Emodule.E | Credit.Result.V"
        ],
        inner_table: {
            status: true,
            parent_key: "bdc",
            data: "Pagos_Mensuales",
            // type: "institution",
        },
        sub: "sub_e5",
        parent_key: "bdc",
        format: "Pagos_Mensuales_Details"
    },
    VI: {
        titlealphabet: "E",
        title: "Emodule.E | Credit.E.Credit.VI",
        concepto:
            "Emodule.E | Credit.Concept.VI",
        resultado: [
            "Emodule.E | Credit.Result.VI"
        ],
        inner_table: {
            status: true,
            parent_key: "bdc",
            data: "Credito_Maximo",
            // type: "institution",
        },
        sub: "sub_e6",
        parent_key: "bdc",
        format: "Credito_Maximo_Details"
    },
    VII: {
        titlealphabet: "E",
        title: "Emodule.E | Credit.E.Credit.VII",
        concepto:
            "Emodule.E | Credit.Concept.VII",
        resultado: [
            "Emodule.E | Credit.Result.VII"
        ],
        inner_table: {
            status: true,
            parent_key: "bdc",
            data: "Historial_Pago",
            // type: "institution",
        },
        sub: "sub_e7",
        parent_key: "bdc",
        format: "Historial_Pago_Details"
    },
    VIII: {
        titlealphabet: "E",
        title: "Emodule.E | Credit.E.Credit.VIII",
        concepto:
            "Emodule.E | Credit.Concept.VIII",
        resultado: [
            "Emodule.E | Credit.Result.VIII"
        ],
        inner_table: {
            status: true,
            parent_key: "bdc",
            data: "Historial_Consultas",
            // type: "institution",
        },
        sub: "sub_e8",
        parent_key: "bdc",
        format: "Historial_Consultas_len"
    },
    IX: {
        titlealphabet: "E",
        title: "Emodule.E | Credit.E.Credit.IX",
        concepto:
            "Emodule.E | Credit.Concept.IX",
        resultado: [
            "Emodule.E | Credit.Result.IX"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_e9",
        parent_key: "bdc",
        format: "Endeudamiento_Total"
    },
    X: {
        titlealphabet: "E",
        title: "Emodule.E | Credit.E.Credit.X",
        concepto:
            "Emodule.E | Credit.Concept.X",
        resultado: [
            "Emodule.E | Credit.Result.X"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_e10",
        parent_key: "bdc",
        format: "scoring"
    },
    // XI: {
    //     titlealphabet: "E",
    //     title: "Emodule.E | Credit.E.Credit.XI",
    //     concepto:
    //         "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Estatus Crédito",
    //     resultado: [
    //         "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Estatus Crédito",
    //     ],
    //     inner_table: {
    //         status: false,
    //         data: "",
    //         type: "",
    //     },
    //     sub: "sub_e11",
    // },
};


export const sectionFData = {
    I: {
        titlealphabet: "F",
        title: "Emodule.F | Document.F.Document.I",
        concepto:
            "Emodule.F | Document.Concept.I",
        resultado: [
            "Emodule.F | Document.Result.I"
        ],
        inner_table: {
            status: true,
            parent_key: "siger",
            data: "Documentos",
            type: "Document",
        },
        sub: "sub_f1",
    },
    II: {
        titlealphabet: "F",
        title: "Emodule.F | Document.F.Document.II",
        concepto:
            "Emodule.F | Document.Concept.II",
        resultado: [
            "Emodule.F | Document.Result.II"
        ],
        inner_table: {
            status: true,
            parent_key: "psm",
            data: "data",
            type: "Publication",
        },
        sub: "sub_f2",
    },
    III: {
        titlealphabet: "F",
        title: "Emodule.F | Document.F.Document.III",
        concepto:
            "Emodule.F | Document.Concept.III",
        resultado: [
            "Emodule.F | Document.Result.III"
        ],
        inner_table: {
            status: true,
            parent_key: "condusef",
            data: "escrituras",
            type: "Escrituras",
        },
        sub: "sub_f3",
    },
    IV: {
        titlealphabet: "F",
        title: "Emodule.F | Document.F.Document.IV",
        concepto:
            "Emodule.F | Document.Concept.IV",
        resultado: [
            "Emodule.F | Document.Result.IV"
        ],
        inner_table: {
            status: true,
            parent_key: "condusef",
            data: "oficios",
            type: "Oficios",
        },
        sub: "sub_f4",
    },
    V: {
        titlealphabet: "F",
        title: "Emodule.F | Document.F.Document.V",
        concepto:
            "Emodule.F | Document.Concept.V",
        resultado: [
            "Emodule.F | Document.Result.V"
        ],
        inner_table: {
            status: false,
            data: "",
            type: "",
        },
        sub: "sub_f5",
        format: "cnbv"
    },
};

export const sectionGData = {
    I: {
        titlealphabet: "G",
        title: "Emodule.G | Establish.G.Establish.I",
        concepto:
            "Emodule.G | Establish.Concept.I",
        resultado: [
            "Emodule.G | Establish.Result.I"
        ],
        inner_table: {
            status: true,
            parent_key: "siem",
            data: "data",
            type: "Establish",
        },
        sub: "sub_g1",
    },
    II: {
        titlealphabet: "G",
        title: "Emodule.G | Establish.G.Establish.II",
        concepto:
            "Emodule.G | Establish.Concept.II",
        resultado: [
            "Emodule.G | Establish.Result.II"
        ],
        inner_table: {
            status: true,
            parent_key: "siem",
            data: "data",
            type: "Economic",
        },
        sub: "sub_g2",
    },
    III: {
        titlealphabet: "G",
        title: "Emodule.G | Establish.G.Establish.III",
        concepto:
            "Emodule.G | Establish.Concept.III",
        resultado: [
            "Emodule.G | Establish.Result.III"
        ],
        inner_table: {
            status: true,
            parent_key: "siem",
            data: "data",
            type: "Export",
        },
        sub: "sub_g3",
    },
    IV: {
        titlealphabet: "G",
        title: "Emodule.G | Establish.G.Establish.IV",
        concepto:
            "Emodule.G | Establish.Concept.IV",
        resultado: [
            "Emodule.G | Establish.Result.IV"
        ],
        inner_table: {
            status: true,
            parent_key: "siem",
            data: "data",
            type: "Import",
        },
        sub: "sub_g4",
    },
};
