// ** React Imports
import { useEffect, useRef, useState } from "react";

// ** MUI Imports
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { Checkbox } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import underLine from "../../assets/images/under-line.png";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NoticePrivacy = (props) => {
  // ** States
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { t } = useTranslation();
  // ** Ref
  const descriptionElementRef = useRef(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (descriptionElementRef.current) {
      descriptionElementRef.current.scrollTop = 0;
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 760,
    maxWidth: "95%",
    height: "75vh",
    overflowX: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 1,
  };
  const textStyle = {
    color: "#1C212D",
    fontFamily: "Gilroy-SemiBold",
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Fragment>
      <Modal
        open={props.open}
        scroll={scroll}
        onClose={props.handleJustClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Box sx={style}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Gilroy-SemiBold",
                fontSize: "20px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("Privacy Notice.Privacy")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
            <Box sx={{ mb: 2, mt: 1 }}>
              <Tabs
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "fit-content",
                  mx: "auto",
                  "& .MuiTabs-scroller": {
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "#EFF1F8",
                    p: 0.8,
                    borderRadius: "4px",
                  },
                  "& .MuiTab-root.MuiButtonBase-root": {
                    minWidth: { xs: 110, md: 145 },
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#4165F6 !important",
                    color: "#ffffff !important",
                    borderRadius: "4px",
                  },
                  "& .MuiTab-textColorPrimary": {
                    py: 2,
                    textTransform: "capitalize",
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
                disableRipple={true}
                value={value}
                onChange={handleChange}
              >
                <Tab
                  label={t("Privacy Notice.ConsultedTab")}
                  {...a11yProps(0)}
                />
                <Tab label={t("Privacy Notice.UsersTab")} {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Box>
          <Box
            sx={{ height: "65%", overflowX: "auto", mt: 2 }}
            ref={descriptionElementRef}
          >
            <TabPanel value={value} index={0}>
              <Typography sx={{ color: "#000000", textAlign: "center", mb: 2 }}>
                <b> {t("Privacy Notice.Consulted.title")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>
                <b>{` ${t("Privacy Notice.Consulted.b1")} `}</b>
                {t("Privacy Notice.Consulted.p1")}{" "}
                <b>({`"${t("Privacy Notice.Consulted.Garangou")}"`})</b>,{" "}
                {t("Privacy Notice.Consulted.p2")}{" "}
                <a href="https://searchlook.mx/" target={"_blank"}>
                  https://searchlook.mx/
                </a>{" "}
                {t("Privacy Notice.Consulted.p3")}{" "}
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p4")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p5")}{" "}
                <b> {t("Privacy Notice.Consulted.Garangou")} </b>{" "}
                {t("Privacy Notice.Consulted.p6")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Consulted.b2")}</b>{" "}
                {t("Privacy Notice.Consulted.p7")}{" "}
                <b>{t("Privacy Notice.Consulted.b3")}</b>{" "}
                {t("Privacy Notice.Consulted.p8")}
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={7}
                  sx={{
                    borderWidth: "1px 0 1px 1px",
                    borderStyle: "solid",
                    borderColor: "#000",
                    p: 1,
                  }}
                >
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    <b>{t("Privacy Notice.Consulted.b4")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={5} sx={{ border: "1px solid #000", p: 1 }}>
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    {t("Privacy Notice.Consulted.p9")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={7}
                  sx={{
                    borderWidth: "0px 0 1px 1px",
                    borderStyle: "solid",
                    borderColor: "#000",
                    p: 1,
                  }}
                >
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    <b>{t("Privacy Notice.Consulted.b5")}</b>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    borderWidth: "0px 1px 1px 1px",
                    borderStyle: "solid",
                    borderColor: "#000",
                    p: 1,
                  }}
                >
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    {t("Privacy Notice.Consulted.p10")}
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ color: "#000000", textAlign: "left", mt: 2 }}>
                <b>{t("Privacy Notice.Consulted.Garangou")} </b>{" "}
                {t("Privacy Notice.Consulted.p11")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p11b")}
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sx={{
                    borderWidth: "1px 0 1px 1px",
                    borderStyle: "solid",
                    borderColor: "#000",
                    p: 1,
                  }}
                >
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    <b>{t("Privacy Notice.Consulted.b6")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{ border: "1px solid #000", p: 1 }}>
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    {t("Privacy Notice.Consulted.p12")}
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Consulted.b7")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.b8")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p13")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p14")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p15")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.b9")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p16")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p17")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.b10")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p18")}{" "}
                <span>
                  (
                  <a href="https://micuenta.infonavit.org.mx/" target="_blank">
                    https://micuenta.infonavit.org.mx/
                  </a>
                  )
                </span>
                {t("Privacy Notice.Consulted.p19")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.imss_b10")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.imss_p18")}{" "}
                <span>
                  (
                  <a href="https://serviciosdigitales.imss.gob.mx/semanascotizadas-web/" target="_blank">
                    https://serviciosdigitales.imss.gob.mx/semanascotizadas-web/
                  </a>
                  )
                </span>
                {t("Privacy Notice.Consulted.imss_p19")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.b11")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p20")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p21")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p22a")}{" "}
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p22b")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.b12")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p23")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p24a")}{" "}
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p24b")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.b13")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p25a")}{" "}
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p25b")}{" "}
                {t("Privacy Notice.Consulted.p25c")}{" "}
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p25d")}{" "}
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p25e")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.b14")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p26")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.b15")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p27")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p28")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.b16")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p29")}{" "}
                <a href="mailto:contacto@searchlook.mx">
                  contacto@searchlook.mx
                </a>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p30")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                {t("Privacy Notice.Consulted.p31a")}{" "}
                <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
                {t("Privacy Notice.Consulted.p31b")}{" "}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p32")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Consulted.b17")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p33")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Consulted.p34")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>
                  {t("Privacy Notice.Consulted.b18")}{" "}
                  <a
                    href="http://inicio.inai.org.mx/SitePages/ifai.aspx"
                    target="_blank"
                  >
                    http://inicio.inai.org.mx/SitePages/ifai.aspx
                  </a>
                </b>
              </Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Typography sx={{ color: "#000000", textAlign: "center", mb: 2 }}>
                <b> {t("Privacy Notice.Users.title")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Users.Garangou")}</b>
                <b>{` ${t("Privacy Notice.Users.b1")} `}</b>
                {t("Privacy Notice.Users.p1")}{" "}
                <b>({`"${t("Privacy Notice.Users.Garangou")}"`})</b>,{" "}
                {t("Privacy Notice.Users.p2")}{" "}
                <a href="https://searchlook.mx/" target={"_blank"}>
                  https://searchlook.mx/
                </a>{" "}
                {t("Privacy Notice.Users.p3")}{" "}
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p4")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p5")}{" "}
                <b> {t("Privacy Notice.Users.Garangou")} </b>{" "}
                {t("Privacy Notice.Users.p6")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Users.b2")}</b>{" "}
                {t("Privacy Notice.Users.p7")}{" "}
                <b>{t("Privacy Notice.Users.b3")}</b>{" "}
                {t("Privacy Notice.Users.p8")}
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={7}
                  sx={{
                    borderWidth: "1px 0 1px 1px",
                    borderStyle: "solid",
                    borderColor: "#000",
                    p: 1,
                  }}
                >
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    <b>{t("Privacy Notice.Users.b4")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={5} sx={{ border: "1px solid #000", p: 1 }}>
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    {t("Privacy Notice.Users.p9")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={7}
                  sx={{
                    borderWidth: "0px 0 1px 1px",
                    borderStyle: "solid",
                    borderColor: "#000",
                    p: 1,
                  }}
                >
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    <b>{t("Privacy Notice.Users.b5")}</b>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    borderWidth: "0px 1px 1px 1px",
                    borderStyle: "solid",
                    borderColor: "#000",
                    p: 1,
                  }}
                >
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    {t("Privacy Notice.Users.p10")}
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ color: "#000000", textAlign: "left", mt: 2 }}>
                <b>{t("Privacy Notice.Users.Garangou")} </b>{" "}
                {t("Privacy Notice.Users.p11")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p11b")}
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sx={{
                    borderWidth: "1px 0 1px 1px",
                    borderStyle: "solid",
                    borderColor: "#000",
                    p: 1,
                  }}
                >
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    <b>{t("Privacy Notice.Users.b6")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{ border: "1px solid #000", p: 1 }}>
                  <Typography sx={{ color: "#000000", textAlign: "left" }}>
                    {t("Privacy Notice.Users.p12a")}{" "}
                    <a href="https://searchlook.mx/" target="_blank">
                      https://searchlook.mx/
                    </a>
                    ; {t("Privacy Notice.Users.p12b")}
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Users.b7")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Users.b8")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p13")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p14")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p15")}
              </Typography>

              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Users.b11")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p20")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p21")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p22a")}{" "}
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p22b")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Users.b12")}</b>
              </Typography>

              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p24a")}{" "}
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p24b")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Users.b13")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p25a")}{" "}
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p25b")}{" "}
                {t("Privacy Notice.Users.p25c")}{" "}
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p25d")}{" "}
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p25e")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Users.b14")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p26")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Users.b15")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p27")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p28")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Users.b16")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p29")}{" "}
                <a href="mailto:contacto@searchlook.mx">
                  contacto@searchlook.mx
                </a>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p30")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                {t("Privacy Notice.Users.p31a")}{" "}
                <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
                {t("Privacy Notice.Users.p31b")}{" "}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p32")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>{t("Privacy Notice.Users.b17")}</b>
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p33")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
                {t("Privacy Notice.Users.p34")}
              </Typography>
              <Typography sx={{ color: "#000000", textAlign: "left" }}>
                <b>
                  {t("Privacy Notice.Users.b18")}{" "}
                  <a
                    href="http://inicio.inai.org.mx/SitePages/ifai.aspx"
                    target="_blank"
                  >
                    http://inicio.inai.org.mx/SitePages/ifai.aspx
                  </a>
                </b>
              </Typography>
            </TabPanel>
          </Box>
          <Box>
            <hr
              style={{
                background: "rgba(255, 255, 255, 0.2)",
                borderColor: "rgba(255, 255, 255, 0.2)",
              }}
            />
            <FormGroup sx={{ alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.isCheckboxChecked}
                    onChange={props.handleCheckboxChange}
                  />
                }
                label={
                  <Box component={"span"} sx={{ color: "gray" }}>
                    {t("guestTermsCondition.accept")}
                    <Box component={"span"} sx={{ color: "#4165F6", ml: 1 }}>
                      {t("Privacy Notice.P17")}
                    </Box>
                  </Box>
                }
              />
            </FormGroup>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Button
                onClick={props.handleClose}
                disabled={!props.isCheckboxChecked}
                sx={{
                  backgroundColor: "primary.main",
                  py: 1,
                  px: { xs: 4, sm: 8 },
                  fontSize: "18px",
                  fontFamily: "Gilroy-Medium",
                  textTransform: "capitalize",
                }}
                variant="contained"
              >
                {t("buttons.buttonTextContinue")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default NoticePrivacy;
