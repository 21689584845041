import axios from 'axios';

export const dczEVerificarLegal = async (dCZ) => {
    const data = new FormData();
    for (const key in dCZ) {
      if (dCZ.hasOwnProperty(key)) {
          data.append(key, dCZ[key]);
      }
  }
    try {
      const response = await axios({
            method: 'POST',
            url: process.env.REACT_APP_BASE_URL2+"legal_e_module_verification",
            data: data,
      })
  
      return response;
    } catch (error) {
      throw error;
    }
  };