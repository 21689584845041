export const dataTypesConfig = {
    CanceledSAT: {
        columnHeaders: [
            "Emodule.table_headers.Supposed",
            "Emodule.table_headers.Amount",
            "Emodule.table_headers.Cancellation Date",
            "Emodule.table_headers.publication date",
            "Emodule.table_headers.Entity"
        ],
        createRow: createData,
        createParams: ["SUPUESTO", "MONTO ", "FECHA DE CANCELACIÓN", "FECHA DE PUBLICACIÓN", "ENTIDAD FEDERATIVA"],
    },
    NotLocated: {
        columnHeaders: [
            "Emodule.table_headers.Supposed",
            "Emodule.table_headers.Date",
            "Emodule.table_headers.Entity"
        ],
        createRow: createLocated,
        createParams: ["SUPUESTO", "FECHAS DE PRIMERA PUBLICACION", "ENTIDAD FEDERATIVA"],
    },
    SATB: {
        columnHeaders: [
            "Emodule.table_headers.Situation",
            "Emodule.table_headers.Date Alleged",
            "Emodule.table_headers.Definitive Date",
            "Emodule.table_headers.Distorted Date",
            "Emodule.table_headers.Favorable Sentence Date",

        ],
        createRow: createSAT,
        createParams: ["SITUACIÓN DEL CONTRIBUYENTE", "PUBLICACIÓN PÁGINA SAT PRESUNTOS", "PUBLICACIÓN PÁGINA SAT DEFINITIVOS", "PUBLICACIÓN PÁGINA SAT DESVIRTUADOS", "PUBLICACIÓN PÁGINA SAT SENTENCIA FAVORABLE"],
    },
    Members: {
        columnHeaders: [
            "Emodule.table_headers.Partner",
            "Emodule.table_headers.RFC/CURP",
            "Emodule.table_headers.Actions",
            "Emodule.table_headers.Worth",
            "Emodule.table_headers.Position",
            "Emodule.table_headers.Legal",
            "Emodule.table_headers.Homonyms",
        ],
        createRow: createMember,
        createParams: ["NOMBRE + APELLIDO PATERNO + APELLIDO MATERNO", "RFC/CURP", "ACCIONES", "VALOR", "POSICION", "LEGAL", "HOMONIMOS"],
    },
    Labor: {
        columnHeaders: [
            "Emodule.table_headers.Instance",
            "Emodule.table_headers.Guy",
            "Emodule.table_headers.Actor",
            "Emodule.table_headers.Defendant",
            "Emodule.table_headers.Proceedings",
            "Emodule.table_headers.Probability",

        ],
        createRow: createLabor,
        createParams: ["Instancia", "Tipo_Juicio", "Actor", "Demandado", "Expediente", "HomonymScore"],
    },
    Repse: {
        columnHeaders: [
            "Emodule.table_headers.Current",
            "Emodule.table_headers.Validity",
            "Emodule.table_headers.RegistrationDate",
            "Emodule.table_headers.Folio",
            "Emodule.table_headers.Registration",
            "Emodule.table_headers.Registration Notice",
            "Emodule.table_headers.Status"

        ],
        createRow: createRepse,
        createParams: ["vigencia", "vigencia", "RegistrationDate", "folio", "registro", "aviso_registro", "entidad"],
    },
    RepseService: {
        columnHeaders: [
            "Emodule.table_headers.Services",

        ],
        createRow: createRepseService,
        createParams: [""],
    },
    Profeco: {
        columnHeaders: [
            "Emodule.table_headers.Year",
            "Emodule.table_headers.Entity",
            "Emodule.table_headers.Complaints Received",
            "Emodule.table_headers.Complaints Concluded",
            "Emodule.table_headers.Reasons",
        ],
        createRow: createProfeco,
        createParams: ["Year", "Entity", "quejas_totales_recibidas", "quejas_concluidas", "principales_motivos_de_reclamacion_a_nivel_nacional"],
    },
    institution: {
        columnHeaders: [
            "Emodule.table_headers.Table based on data",
        ],
        createRow: createInstitution,
        createParams: ["Tablebasedondata"],
    },
    Document: {
        columnHeaders: [
            "Emodule.table_headers.Act",
            "Emodule.table_headers.Modality",
            "Emodule.table_headers.NCI / Document No.",
            "Emodule.table_headers.Registration Information",
            "Emodule.table_headers.Summary",
            "Emodule.table_headers.PDF",
        ],
        createRow: createDocument,
        createParams: ["ACTO", "MODALIDAD", "NCI/NUMERO DE DOCUMENTO", "ANTECEDENTE", "SUMMARY", "PDF"],
    },
    Publication: {
        columnHeaders: [
            "Emodule.table_headers.Guy",
            "Emodule.table_headers.Name",
            "Emodule.table_headers.No.",
            "Emodule.table_headers.Date",
            "Emodule.table_headers.PDF", ,
        ],
        createRow: createPublication,
        createParams: ["tipo_de_publicacion", "nombre_de_la_publicacion", "no_publicacion", "fecha", "pdf"],
    },
    Establish: {
        columnHeaders: [
            "Emodule.table_headers.Tradename",
            "Emodule.table_headers.Company Type",
            "Emodule.table_headers.Type of Facilities",
            "Emodule.table_headers.Location",
            "Emodule.table_headers.Contact",
        ],
        createRow: createEstablish,
        createParams: ["nombre_comercial",
            "PERFIL_GENERAL_DE_LA_EMPRESA.perfil.tipo_de_empresa",
            "PERFIL_GENERAL_DE_LA_EMPRESA.perfil.tipo_de_instalaciones ",
            "datos_generales.ubicacion_territorial.entidad_federativa + datos_generales.ubicacion_territorial.delegacion_o_municipio",
            "datos_generales.datos_de_contacto.telefono"
        ],
    },
    Economic: {
        columnHeaders: [
            "Emodule.table_headers.Tradename",
            "Emodule.table_headers.Activities",
            "Emodule.table_headers.Products or Services",
            "Emodule.table_headers.Percentage",
            "Emodule.table_headers.Activity Code",
        ],
        createRow: createEconomic,
        createParams: ["nombre_comercial", "ACTIVIDAD_ECONOMICA.actividades_productos_y_servicios.actividad_principal", "ACTIVIDAD_ECONOMICA.actividades_productos_y_servicios.producto_o_servicio_principal_1", "ACTIVIDAD_ECONOMICA.actividades_productos_y_servicios.porcentaje_1", "ACTIVIDAD_ECONOMICA.actividades_productos_y_servicios.actividad_codigo"],
    },
    Export: {
        columnHeaders: [
            "Emodule.table_headers.Tradename",
            "Emodule.table_headers.Does it Export?",
            "Emodule.table_headers.Countries",
        ],
        createRow: createExport,
        createParams: ["nombre_comercial", "PERFIL_GENERAL_DE_LA_EMPRESA.exportacion_e_importacion.exporta", "PERFIL_GENERAL_DE_LA_EMPRESA.exportacion_e_importacion.paises_1"],
    },
    Import: {
        columnHeaders: [
            "Emodule.table_headers.Tradename",
            "Emodule.table_headers.Does it Import?",
            "Emodule.table_headers.Countries",
        ],
        createRow: createExport,
        createParams: ["nombre_comercial", "PERFIL_GENERAL_DE_LA_EMPRESA.exportacion_e_importacion.importa", "PERFIL_GENERAL_DE_LA_EMPRESA.exportacion_e_importacion.paises_2"],
    },
    Escrituras: {
        columnHeaders: [
            "Emodule.table_headers.Guy",
            "Emodule.table_headers.Deed and date",
            "Emodule.table_headers.Commercial Folio and date",
            "Emodule.table_headers.NotaryM",
            "Emodule.table_headers.NotaryF",
        ],
        createRow: createEscrituras,
        createParams: ["tipo", "escritura.numero - escritura.fecha", "folio_mercantil.numero - folio_mercantil.fecha", "fedatario.numero + ' ' + fedatario.municipio + fedatario.estado", "fedatario.notario"],
    },

    Oficios: {
        columnHeaders: [
            "Emodule.table_headers.Guy",
            "Emodule.table_headers.Folio",
            "Emodule.table_headers.Date",
            "Emodule.table_headers.Subject",
        ],
        createRow: createOficios,
        createParams: ["tipo", "numero", "fecha", "asunto"],
    },
    Pycs: {
        columnHeaders: [
            "Emodule.table_headers.Law violated",
            "Emodule.table_headers.File",
            "Emodule.table_headers.Fine",
            "Emodule.table_headers.Disqualification period",
            "Emodule.table_headers.Cause",
        ],
        createRow: createPycs,
        createParams: ["ley_infringida", "expediente", "monto_de_la_multa", "periodo_de_inhabilitacion", "ficha_tecnica_del_infractor.causa"],
    },
    Licitaciones: {
        columnHeaders: [
            "Emodule.table_headers.Institution",
            "Emodule.table_headers.Guy",
            "Emodule.table_headers.Contract",
            "Emodule.table_headers.Period",
            "Emodule.table_headers.Amount",
        ],
        createRow: createLicitaciones,
        createParams: ["siglas_de_la_institucion", "plantilla_del_expediente", "descripcion_del_contrato", "periodo", "importe_del_contrato"],
    },
    Mensajes: {
        columnHeaders: [
            "Emodule.table_headers.Message Type",
            "Emodule.table_headers.Key",
            "Emodule.table_headers.Responsibility",
            "Emodule.table_headers.Date",
            "Emodule.table_headers.Description",
        ],
        createRow: createMensajes,
        createParams: ["tipo", "categoria", "responsabilidad", "fecha", "descripcion"],
    },
    
    Servidores: {
        columnHeaders: [
            "Emodule.table_headers.Public Servant",
            "Emodule.table_headers.Agency",
            "Emodule.table_headers.Sanction",
            "Emodule.table_headers.Cause and File",
            "Emodule.table_headers.Sanction Period",
            "Emodule.table_headers.Amount",

        ],
        createRow: createServidores,
        createParams: ["servidor_publico", "dependencia", "sancion_impuesta", "causa - expediente", "inicio  - fin" , "monto"],
    },
};

// Functions
export function createData(Supposed, Amount, CancellationDate, publicationdate, Entity) {
    return { Supposed, Amount, CancellationDate, publicationdate, Entity };
}

export function createLocated(Supposed, Date, Entity) {
    return { Supposed, Date, Entity };
}

export function createSAT(Situation, DateAlleged, DistortedDate, DefinitiveDate, FavorableSentenceDate) {
    return { Situation, DateAlleged, DistortedDate, DefinitiveDate, FavorableSentenceDate };
}

export function createMember(partner, rfc_curp, Acciones, Valor, Posicion, Legal, HOMONIMOS) {
    return { partner, rfc_curp, Acciones, Valor, Posicion, Legal, HOMONIMOS };
}

export function createLabor(Instance, Guy, Actor, Defendant, Proceedings, Probability) {
    return { Instance, Guy, Actor, Defendant, Proceedings, Probability };
}

export function createRepse(Current, Validity, RegistrationDate, Folio, Registration, RegistrationNotice, Status) {
    return { Current, Validity, RegistrationDate, Folio, Registration, RegistrationNotice, Status };
}

export function createRepseService(Services) {
    return { Services };
}
export function createProfeco(Year, Entity, ComplaintsReceived, ComplaintsConcluded, Reasons) {
    return { Year, Entity, ComplaintsReceived, ComplaintsConcluded, Reasons };
}

export function createInstitution(Tablebasedondata) {
    return { Tablebasedondata };
}

export function createDocument(ACTO, FECHA_DE_INGRESO, FECHA_DE_INSCRIPCION, FECHA_INSERCION, SUMMARY, PDF) {
    return { ACTO, FECHA_DE_INGRESO, FECHA_DE_INSCRIPCION, FECHA_INSERCION, SUMMARY, PDF };
}

export function createPublication(Guy, Name, No, Date, PDF) {
    return { Guy, Name, No, Date, PDF };
}

export function createEstablish(Tradename, CompanyType, TypeofFacilities, Location, Contact) {
    return { Tradename, CompanyType, TypeofFacilities, Location, Contact };
}

export function createEconomic(Tradename, Activities, ProductsorServices, Percentage, ActivityCode) {
    return { Tradename, Activities, ProductsorServices, Percentage, ActivityCode };
}

export function createExport(Tradename, Doesitmatter, Countries) {
    return { Tradename, Doesitmatter, Countries };
}
export function createEscrituras(Guy, DeedAndDate, CommercialFolioAndDate, Notary1, Notary2) {
    return { Guy, DeedAndDate, CommercialFolioAndDate, Notary1, Notary2 };
}

export function createOficios(Guy, Folio, Date, Subject) {
    return { Guy, Folio, Date, Subject };
}

export function createPycs(Law, File, Fine, Disqualification ,Cause) {
    return {Law, File, Fine, Disqualification ,Cause};
}

export function createLicitaciones(Institution, Guy, Contract, Period ,Amount) {
    return {Institution, Guy, Contract, Period ,Amount};
}

export function createMensajes(Message, Key, Responsibility, Date ,Description) {
    return {Message, Key, Responsibility, Date ,Description};
}

export function createServidores(PublicServant, Agency, Sanction, Cause ,SanctionPeriod , Amount) {
    return {PublicServant, Agency, Sanction, Cause ,SanctionPeriod , Amount};
}


