// ** React Imports
import { useEffect, useRef, useState } from "react";

// ** MUI Imports
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TermsConditions = (props) => {
  // ** States
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const { t } = useTranslation();
  // ** Ref
  const descriptionElementRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (descriptionElementRef.current) {
      descriptionElementRef.current.scrollTop = 0;
    }
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div className="demo-space-x">
      {/* <Button variant='outlined' onClick={handleClickOpen('paper')}>
        scroll=paper
      </Button> */}
      <Dialog
        open={props.openTerms}
        scroll={scroll}
        onClose={props.handleTermsClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          {t("guestTermsCondition.terms")}
        </DialogTitle>
        <Box sx={{ mb: 3 }}>
          <Tabs
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "fit-content",
              mx: "auto",
              "& .MuiTabs-scroller": {
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: "#EFF1F8",
                p: 0.8,
                borderRadius: "4px",
              },
              "& .MuiTab-root.MuiButtonBase-root": {
                minWidth: { xs: 110, md: 145 },
              },
              "& .Mui-selected": {
                backgroundColor: "#4165F6 !important",
                color: "#ffffff !important",
                borderRadius: "4px",
              },
              "& .MuiTab-textColorPrimary": {
                py: 2,
                textTransform: "capitalize",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
            disableRipple={true}
            value={value}
            onChange={handleChange}
          >
            <Tab label={t("Privacy Notice.ConsultedTab")} {...a11yProps(0)} />
            <Tab label={t("Privacy Notice.UsersTab")} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <DialogContent
          dividers={scroll === "paper"}
          ref={descriptionElementRef}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <TabPanel value={value} index={0}>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 3 }}>
                <b> {t("guestTermsCondition.Consulted.b1")}</b>
              </Typography>

              <Grid container sx={{ color: "#000000", textAlign: "left" }}>
                <Typography
                  sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                >
                  <Box component="span" pr={2}>
                    <b style={{ padding: "0" }}>I.</b>
                  </Box>
                  {t("guestTermsCondition.Consulted.p1")}
                  <b>{t("guestTermsCondition.Consulted.USER")}</b>
                  {t("guestTermsCondition.Consulted.p2")}
                </Typography>
                {/* Section 1 */}
                <Box sx={{ color: "#000000", textAlign: "left", pl: 1 }}>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>
                    {t("guestTermsCondition.Consulted.p3")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.Consulted.p4")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      3.
                    </Box>
                    {t("guestTermsCondition.Consulted.p5")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p6")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p7")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      4.
                    </Box>
                    {t("guestTermsCondition.Consulted.p8")}
                    <b>{t("guestTermsCondition.Consulted.SERVICES")}</b>
                    {t("guestTermsCondition.Consulted.p9")}
                    <b>"{t("guestTermsCondition.Consulted.SERVICES")}"</b>
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      5.
                    </Box>
                    {t("guestTermsCondition.Consulted.p10")}
                    <b>"{t("guestTermsCondition.Consulted.SERVICES")}"</b>
                    {t("guestTermsCondition.Consulted.p11")}
                  </Typography>
                </Box>

                {/* Section 2 */}
                <Typography
                  sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                >
                  <Box component="span" pr={2}>
                    <b style={{ padding: "0" }}>II.</b>
                  </Box>
                  <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                  {t("guestTermsCondition.Consulted.p12")}
                </Typography>

                <Box sx={{ color: "#000000", textAlign: "left", pl: 1 }}>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>
                    {t("guestTermsCondition.Consulted.p13")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.Consulted.p14")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      3.
                    </Box>
                    {t("guestTermsCondition.Consulted.p15")}
                    <b>{t("guestTermsCondition.Consulted.SERVICES")}</b>
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      4.
                    </Box>
                    {t("guestTermsCondition.Consulted.p16")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      5.
                    </Box>
                    {t("guestTermsCondition.Consulted.p17")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p18")}
                    <b> {t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p19")}
                    <b>{t("guestTermsCondition.Consulted.CUSTOMER")}</b>
                    {t("guestTermsCondition.Consulted.p20")}
                    <b>"{t("guestTermsCondition.Consulted.Garangou")}"</b>
                  </Typography>
                </Box>

                {/* Section 3 */}
                <Typography
                  sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                >
                  <Box component="span" pr={2}>
                    <b style={{ padding: "0" }}>III.</b>
                  </Box>
                  {t("guestTermsCondition.Consulted.p21")}
                </Typography>

                <Box sx={{ color: "#000000", textAlign: "left", pl: 1 }}>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p22")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p23")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "center",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b3")}</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b4")}</b>
                    {t("guestTermsCondition.Consulted.p24")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p25")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p26")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p27")}
                    <a href="https://searchlook.mx/">https://searchlook.mx/</a>
                    {t("guestTermsCondition.Consulted.p28")}
                    <b>{t("guestTermsCondition.Consulted.SERVICES")}</b>
                    {t("guestTermsCondition.Consulted.p28a")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b5")}</b>
                    {t("guestTermsCondition.Consulted.p29")}
                    &nbsp;
                    <b>
                      {t("guestTermsCondition.Consulted.SERVICES")}, &nbsp;
                      {t("guestTermsCondition.Consulted.Garangou")}
                    </b>
                    {t("guestTermsCondition.Consulted.p30")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.Consulted.p31")}
                    <b> "X"</b> {t("guestTermsCondition.Consulted.p32")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b6")}</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>
                    {t("guestTermsCondition.Consulted.p33")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p34")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}.</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.Consulted.p35")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}.</b>
                    {t("guestTermsCondition.Consulted.p36")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p37")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      3.
                    </Box>
                    {t("guestTermsCondition.Consulted.p38")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}.</b>
                    {t("guestTermsCondition.Consulted.p39")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b7")}. &nbsp;</b>
                    {t("guestTermsCondition.Consulted.p40")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>
                    {t("guestTermsCondition.Consulted.p41")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p42")}
                    <a href="https://searchlook.mx/">https://searchlook.mx/</a>. &nbsp;
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p43")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p44")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p45")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.Consulted.p46")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p47")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}.</b>
                    {t("guestTermsCondition.Consulted.p48")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p49")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.Consulted.p50")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p51")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b8")}</b>
                    {t("guestTermsCondition.Consulted.p52")}
                    <b>{t("guestTermsCondition.Consulted.SERVICES")}</b>
                    {t("guestTermsCondition.Consulted.p53")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p54")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b9")}</b>
                    {t("guestTermsCondition.Consulted.p56")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p57")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p58")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p59")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>.
                    {t("guestTermsCondition.Consulted.p60")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p61")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p62")}
                    <b>{t("guestTermsCondition.Consulted.SERVICES")}</b>
                    {t("guestTermsCondition.Consulted.p63")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b10")}</b>
                    {t("guestTermsCondition.Consulted.p64")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b11")}</b>
                    {t("guestTermsCondition.Consulted.p65")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p66")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p67")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p68")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p69")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p70")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b12")}</b>
                    {t("guestTermsCondition.Consulted.p71")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p72")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p73")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b13")}</b>
                    {t("guestTermsCondition.Consulted.p74")}
                    <b>{t("guestTermsCondition.Consulted.SERVICES")}</b>
                    {t("guestTermsCondition.Consulted.p75")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p76")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.Consulted.p77")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p78")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p79")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b14")}</b>
                    {t("guestTermsCondition.Consulted.p80")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p81")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p82")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p83")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.Consulted.p84")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p85")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p86")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.Consulted.p87")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p88")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p89")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p90")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p91")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p92")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b15")}</b>
                    {t("guestTermsCondition.Consulted.p93")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p94")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p95")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>

                    {t("guestTermsCondition.Consulted.p96")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.Consulted.p97")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p98")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p99")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      3.
                    </Box>
                    {t("guestTermsCondition.Consulted.p100")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}.-</b>
                    {t("guestTermsCondition.Consulted.p101")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p102")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p103")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p104")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}.</b>
                    {t("guestTermsCondition.Consulted.p105")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p106")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p107")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      4.
                    </Box>

                    {t("guestTermsCondition.Consulted.p108")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b16")}</b>
                    {t("guestTermsCondition.Consulted.p109")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b17")}</b>
                    {t("guestTermsCondition.Consulted.p110")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b18")}</b>
                    {t("guestTermsCondition.Consulted.p111")}
                    <b>{t("guestTermsCondition.Consulted.SERVICES")}</b>
                    {t("guestTermsCondition.Consulted.p112")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b19")}</b>
                    {t("guestTermsCondition.Consulted.p113")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b20")}</b>
                    {t("guestTermsCondition.Consulted.p114")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b21")}</b>
                    {t("guestTermsCondition.Consulted.p115")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p116")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p117")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p118")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p119")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.b22")}</b>
                    {t("guestTermsCondition.Consulted.p120")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.p121")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.p122")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.Consulted.imss_b22")}</b>
                    {t("guestTermsCondition.Consulted.imss_p120")}
                    <b>{t("guestTermsCondition.Consulted.USER")}</b>
                    {t("guestTermsCondition.Consulted.imss_p121")}
                    <b>{t("guestTermsCondition.Consulted.Garangou")}</b>
                    {t("guestTermsCondition.Consulted.imss_p122")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "center",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.Consulted.p123")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "center",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.Consulted.p124")}
                  </Typography>
                </Box>
              </Grid>
            </TabPanel>

            {/* **********USER TAB INFO*****************/}

            <TabPanel value={value} index={1}>
              <Typography sx={{ color: "#000000", textAlign: "left", mb: 3 }}>
                <b> {t("guestTermsCondition.User.b1")}</b>
              </Typography>

              <Grid container sx={{ color: "#000000", textAlign: "left" }}>
                <Typography
                  sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                >
                  <Box component="span" pr={2}>
                    <b style={{ padding: "0" }}>I.</b>
                  </Box>
                  {localStorage.getItem("selectedLanguage") === "en" ? (
                    <>
                      {t("guestTermsCondition.User.p1")}
                      <b>{t("guestTermsCondition.User.USER")}</b>
                      {t("guestTermsCondition.User.p2")}
                    </>
                  ) : (
                    <>
                      {"Declara el "} <b>{"USUARIO,"}</b>
                      {" por su propio derecho:"}
                    </>
                  )}
                </Typography>
                {/* Section 1 */}
                <Box sx={{ color: "#000000", textAlign: "left", pl: 1 }}>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>
                    {t("guestTermsCondition.User.p3")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.User.p4")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      3.
                    </Box>
                    {t("guestTermsCondition.User.p5")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p6")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p7")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      4.
                    </Box>
                    {t("guestTermsCondition.User.p8")}
                    <b>
                      &nbsp;{t("guestTermsCondition.User.Garangou")} &nbsp;
                      {t("guestTermsCondition.User.SERVICES")}
                    </b>
                    {t("guestTermsCondition.User.p9")}
                    <b>"{t("guestTermsCondition.User.SERVICES")}"</b>
                    {t("guestTermsCondition.User.p10")}
                    <b>"{t("guestTermsCondition.User.Garangou")}"</b>
                    {t("guestTermsCondition.User.p11")}
                  </Typography>
                </Box>

                {/* Section 2 */}
                <Typography
                  sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                >
                  <Box component="span" pr={2}>
                    <b style={{ padding: "0" }}>II.</b>
                  </Box>
                  <b>{t("guestTermsCondition.User.Garangou")}</b>
                  {t("guestTermsCondition.User.p12")}
                </Typography>

                <Box sx={{ color: "#000000", textAlign: "left", pl: 1 }}>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>
                    {t("guestTermsCondition.User.p13")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.User.p14")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      3.
                    </Box>
                    {t("guestTermsCondition.User.p15")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p15a")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      4.
                    </Box>
                    {t("guestTermsCondition.User.p16")}
                  </Typography>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <Box component="span" pr={1}>
                      5.
                    </Box>
                    {t("guestTermsCondition.User.p17")}
                    <b>"{t("guestTermsCondition.User.Garangou")}"</b>
                  </Typography>
                </Box>

                {/* Section 3 */}
                <Typography
                  sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                >
                  <Box component="span" pr={2}>
                    <b style={{ padding: "0" }}>III.</b>
                  </Box>
                  {t("guestTermsCondition.User.p21")}
                </Typography>

                <Box sx={{ color: "#000000", textAlign: "left", pl: 1 }}>
                  <Typography
                    sx={{ color: "#000000", textAlign: "left", mb: 2, pl: 2 }}
                  >
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p22")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p23")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "center",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b3")}</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b4")}</b>
                    {t("guestTermsCondition.User.p24")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p24a")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p25")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p26")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p27")}
                    <a href="https://searchlook.mx/">https://searchlook.mx/</a>
                    {t("guestTermsCondition.User.p27a")}
                    <b>{t("guestTermsCondition.User.SERVICES")}</b>
                    {t("guestTermsCondition.User.p27b")}
                    <b>{t("guestTermsCondition.User.CLIENT")}</b>
                    {t("guestTermsCondition.User.p27c")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b5")}</b>
                    {t("guestTermsCondition.User.p29")}
                    <b>
                      {t("guestTermsCondition.User.SERVICES")},&nbsp;
                      {t("guestTermsCondition.User.Garangou")}
                    </b>
                    {t("guestTermsCondition.User.p29a")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p29b")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p29c")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p30")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p31")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p32")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b6")}</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p33")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p34")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p35")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p36")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p37")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p38")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p39")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p40")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p41")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p42")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p43")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p44")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p45")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p46")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p47")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p48")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p49")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b7")}. &nbsp;</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>
                    {t("guestTermsCondition.User.p50")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p51")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p52")}
                    <b>{t("guestTermsCondition.User.Garangou")}.</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.User.p53")}
                    <b>{t("guestTermsCondition.User.Garangou")}.</b>
                    {t("guestTermsCondition.User.p54")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p55")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      3.
                    </Box>
                    {t("guestTermsCondition.User.p56")}
                    <b>{t("guestTermsCondition.User.USER")}'S</b>
                    {t("guestTermsCondition.User.p57")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p58")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p59")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p60")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p61")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      4.
                    </Box>
                    {t("guestTermsCondition.User.p62")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p63")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      5.
                    </Box>
                    {t("guestTermsCondition.User.p64")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p65")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p66")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b8")}</b>
                    {t("guestTermsCondition.User.p67")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>
                    <b>{t("guestTermsCondition.User.p68")}</b>
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p69")}
                    <a href="https://searchlook.mx/">
                      https://searchlook.mx/
                    </a>. &nbsp;
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p70")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p71")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p72")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.User.p73")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p74")}
                    <b>{t("guestTermsCondition.User.Garangou")}.</b>
                    {t("guestTermsCondition.User.p75")}
                    <b>{t("guestTermsCondition.User.USER")}.</b>
                    {t("guestTermsCondition.User.p76")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p77")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p78")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      3.
                    </Box>
                    {t("guestTermsCondition.User.p79")}
                    <b>{t("guestTermsCondition.User.USER")}.- </b>
                    {t("guestTermsCondition.User.p80")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p81")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p82")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      4.
                    </Box>
                    {t("guestTermsCondition.User.p83")}
                    <b>{t("guestTermsCondition.User.USER")}'S</b>
                    {t("guestTermsCondition.User.p84")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p85")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p86")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p87")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b9")}</b>
                    {t("guestTermsCondition.User.p88")}
                    <b>{t("guestTermsCondition.User.SERVICES")}</b>
                    {t("guestTermsCondition.User.p89")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p90")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p91")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p92")}
                    <b>{t("guestTermsCondition.User.SERVICES")}</b>
                    {t("guestTermsCondition.User.p93")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b10")}</b>
                    {t("guestTermsCondition.User.p94")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p95")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p95a")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p96")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p97")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p98")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p99")}
                    <b>{t("guestTermsCondition.User.SERVICES")}</b>
                    {t("guestTermsCondition.User.p100")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b11")}</b>
                    {t("guestTermsCondition.User.p101")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b12")}</b>
                    {t("guestTermsCondition.User.p102")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p103")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p104")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p105")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p106")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p107")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>
                    {t("guestTermsCondition.User.p108")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p109")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p110")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.User.p111")}
                    <b>{t("guestTermsCondition.User.Garangou")}'S</b>
                    {t("guestTermsCondition.User.p112")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p113")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p114")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      3.
                    </Box>
                    {t("guestTermsCondition.User.p115")}
                    <b>{t("guestTermsCondition.User.SERVICES")}</b>
                    {t("guestTermsCondition.User.p116")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p117")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p118")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p119")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      4.
                    </Box>
                    {t("guestTermsCondition.User.p120")}
                    <b>{t("guestTermsCondition.User.SERVICES")}</b>
                    {t("guestTermsCondition.User.p121")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b13")}</b>
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p122")}
                    <b>{t("guestTermsCondition.User.SERVICES")}</b>
                    {t("guestTermsCondition.User.p122a")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p123")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p124")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p125")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p126")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p127")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p128")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p129")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p130")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p131")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p132")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b14")}</b>
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p133")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p134")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p135")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p136")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b15")}</b>
                    {t("guestTermsCondition.User.p137")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p138")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p139")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p140")}
                    <b>{t("guestTermsCondition.User.USER")}.</b>
                    &nbsp;
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p141")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p142")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p143")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b16")}</b>
                    {t("guestTermsCondition.User.p144")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p145")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p146")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p147")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p148")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p149")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p150")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p151")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p152")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p153")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p154")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p155")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p156")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p157")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p158")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p159")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b17")}</b>
                    {t("guestTermsCondition.User.p160")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p161")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p162")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b18")}</b>
                    {t("guestTermsCondition.User.p163")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p164")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p165")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b19")}</b>
                    {t("guestTermsCondition.User.p166")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p167")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p168")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b20")}</b>
                    {t("guestTermsCondition.User.p169")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p170")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p171")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      1.
                    </Box>
                    {t("guestTermsCondition.User.p172")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      2.
                    </Box>
                    {t("guestTermsCondition.User.p173")}
                    <b>{t("guestTermsCondition.User.Garangou")} </b>
                    {t("guestTermsCondition.User.p174")}
                    <b>{t("guestTermsCondition.User.USER")} </b>
                    {t("guestTermsCondition.User.p175")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      3.
                    </Box>
                    {t("guestTermsCondition.User.p176")}
                    <b>{t("guestTermsCondition.User.Garangou")}.-</b>
                    {t("guestTermsCondition.User.p177")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p178")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p178a")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p179")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p180")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p181")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p182")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <Box component="span" pr={1}>
                      4.
                    </Box>
                    {t("guestTermsCondition.User.p183")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b21")}</b>
                    {t("guestTermsCondition.User.p184")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b22")}</b>
                    {t("guestTermsCondition.User.p185")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b23")}</b>
                    {t("guestTermsCondition.User.p186")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p187")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p188")}
                    <b>{t("guestTermsCondition.User.USER")}</b>
                    {t("guestTermsCondition.User.p189")}
                    <b>{t("guestTermsCondition.User.Garangou")}</b>
                    {t("guestTermsCondition.User.p190")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b24")}</b>
                    {t("guestTermsCondition.User.p191")}
                    <b>{t("guestTermsCondition.User.SERVICES")}</b>
                    {t("guestTermsCondition.User.p192")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b25")}</b>
                    {t("guestTermsCondition.User.p193")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "left",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    <b>{t("guestTermsCondition.User.b26")}</b>
                    {t("guestTermsCondition.User.p194")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "center",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p195")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "center",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p196")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      textAlign: "center",
                      mt: 3,
                      mb: 3,
                      pl: 2,
                    }}
                  >
                    {t("guestTermsCondition.User.p197")}
                  </Typography>
                </Box>
              </Grid>
            </TabPanel>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={props.handleTermsClose}
            sx={{
              borderColor: "primary.main",
              color: "primary.main",
              fontSize: "14px",
              fontFamily: "Gilroy-Medium",
              textTransform: "capitalize !important",
            }}
            variant="outlined"
          >
            {t("buttons.back")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TermsConditions;
