import { Box } from "@mui/material";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Menubar from "../@common/Menubar/Menubar";

const AppLayout = () => {
  const location = useLocation();

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Menubar />
        <Box
          component="main"
          sx={{
            p: { xs: 1, sm: 2, lg: 3 },
            mt: 16,
            mx: "auto",
            maxWidth: {
              xs: location.pathname === "/report" ? "100%" : location.pathname === "/Ereport" ? "100%" : "90%",
              sm: location.pathname === "/report" ? "100%" :location.pathname === "/Ereport" ? "100%" :  "90%",
              lg:
                location.pathname === "/report"
                  ? "100%"
                  : location.pathname === "/authorizationpanel"
                    ? "90%"
                    : location.pathname === "/Ereport"
                      ? "100%"
                      : "1200px",
              xl: "1400px",
            },
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default AppLayout;
