import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import underLine from "../../assets/images/under-line.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LabelInputLeftIcon from "../@common/Input/LabelInputLeftIcon";
import PasswordShowHideIcon from "../@common/Input/PasswordShowHideIcon/PasswordShowHideIcon";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import google from "../../assets/images/google.png";
import microSoft from "../../assets/images/microsoft.png";
import Footer from "../../Footer/Footer";
import bgImage from "../../assets/images/coverphoto.png";
import AnimationLoginScreen from "../@common/AnimationLoginScreen/AnimationLoginScreen";
import mailImage from "../../assets/images/mailIcon.png";
import sjcl from "sjcl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AlertModal from "../@common/Modal/AlertModal";
import { emailPattern } from "../../utils/InputPatterns";
import { validateInput } from "../../utils/inputValidation"; // Import this from the lib
import { useGoogleLogin } from "@react-oauth/google";
import { OktaAuth } from "@okta/okta-auth-js";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../utils/msalConfig";
import { msalConfigLala } from "../../utils/msalConfigLala";
import { getIP } from "../../utils/getIP";
import { call_api_function } from "../../utils/verifyAPICalls";
import { useAuth0 } from "@auth0/auth0-react";
import oktaimage from "../../assets/images/okta.png";
import { useTranslation } from "react-i18next";
import AuthLeftScreen from "../@common/AuthLeftScreen";
import LoginImage from "../../assets/images/loginRounded.png";
import { oktaConfig } from "../../utils/oktaConfig";


function SignIn({ is_lala = false }) {
  const { t } = useTranslation();
  // Initialize necessary hooks or context for authentication
  // const { loginWithRedirect } = useAuth0();
  // const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowText] = useState("");
  const [tokenId, setokenId] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [homeAccountId, sethomeAccountId] = useState("");
  const [multipleSession, setMultipleSessionFlag] = useState(false);
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const GuestUserHandler = () => {
    navigate("/GuestUser");
  };
  const authClient = new OktaAuth(oktaConfig);

  // const { logout } = useAuth0();
  const loginWithOkta = async () => {
    if (authClient.isLoginRedirect()) {
      try {
        await authClient.handleRedirect();
      } catch (e) {
        console.error('OktaError:', e);
      }
    } else if (!await authClient.isAuthenticated()) {
      // Start the browser based oidc flow, then parse tokens from the redirect callback url
      authClient.signInWithRedirect();
    } else {
      console.log('User is already OKTA authenticated');
      let user = await authClient.getUser();
      console.log(user);
      loginWithGoogleAndMicrosoft(user.email);
      // this.asyncLocalStorage('okta-token-storage').then(async (oktaStore)=> {
      //   const isLogin = Boolean(await this.asyncLocalStorage('isLogin'))
      //   console.log('islogin in containerMain' ,Boolean(isLogin))
      //   console.log(typeof oktaStore)
      //   if (oktaStore) {
      //       const oktaToken = JSON.parse(oktaStore)
      //       console.log('redirecciona a login, debe ser true' , oktaToken)
      //       if (oktaToken.idToken && oktaToken.idToken.claims && !isLogin) {
      //         loginWithGoogleAndMicrosoft(oktaToken.idToken.claims.email)
      //       }
      //   }
      // })
    }
  };

  const handleClick = async (e) => {
    console.log("handle click is login at 77");
    const errors = {};
    validateInput(email, emailPattern, "email", errors, t);
    if (password === "" || password === null) {
      errors.password = t("Login.popup.inputPasswordRedMessageEnterThePass");
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      await loginUser(email, password, false);
    }
  };
  const continureMultiSession = async (email) => {
    const formData = new FormData();
    let email_ = email.toLowerCase();
    formData.append("email", email_);
    try {
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "allow-multiple",
        formData
      );
      if (response.data == "ok") {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  const handleCloseAlert = async () => {
    if (multipleSession) {
      setMultipleSessionFlag(false);
      const resp = await continureMultiSession(email);
      if (!resp) {
        setShowText(t("thanks.check"));
        return;
      }
    }
    setShowAlert(false);
  };
  const handleMicrosoft = async () => {
    const msalInstance = new PublicClientApplication(is_lala ? msalConfigLala : msalConfig);
    const loginRequest = {
      scopes: ["user.read"],
    };
    try {
      const authResult = await msalInstance.loginPopup(loginRequest);
      sethomeAccountId(authResult.account.homeAccountId);
      loginWithGoogleAndMicrosoft(authResult.account.username);
    } catch (error) {
      setShowAlert(true);
      setShowText(error.message);
    }
  };
  useEffect(() => {
    const msalInstance = new PublicClientApplication(is_lala ? msalConfigLala : msalConfig);
    msalInstance.handleRedirectPromise().then((result) => {
      if (result) {
        setUserInfo(result.account);
      }
    });
  }, []);

  const loginWithGoogleAndMicrosoft = async (email) => {
    var correo = email;
    var arrayData = [];
    arrayData.push(correo);
    var dataForm = {
      data_eel: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, arrayData[0], {
        iter: 500,
      }),
    };
    var dataFormJsonEll = JSON.parse(dataForm["data_eel"]);
    var dataForm_ = {
      data_eel: {
        data_eeli: dataFormJsonEll["iv"],
        data_eels: dataFormJsonEll["salt"],
        data_eelc: dataFormJsonEll["ct"],
      },
    };
    const formData = new FormData();

    // Append the data_eel and data_epd objects to the FormData
    formData.append("data_eel", JSON.stringify(dataForm_["data_eel"]));
    await axios({
      method: "post",
      url: process.env.REACT_APP_BASE_URL2 + "ingresar_google",
      data: formData,
    })
      .then((response) => {
        if (
          response.status === 200 &&
          Array.isArray(response.data) &&
          response.data.length > 0 &&
          response.data !== "no existe"
        ) {
          // Assuming that response.data contains the user information array
          Cookies.set("userInfo", response.data);
          navigate("/Dashboard");
        } else if (response.data === "User is already logged in") {
          setShowAlert(true);
          setShowText(t("thanks.useralready"));
        } else if (response.data === "registro no autorizado") {
          setShowAlert(true);
          setShowText(t("Login.popup.loginPopUpMessageNotValidEmail"));
        } else if (response.status === 200 && response.data === "no existe") {
          setShowAlert(true);
          setShowText(t("Login.popup.loginPopUpEmailPassIncorrect"));
        } else if (response.data === "no validado") {
          setShowAlert(true);
          setShowText(t("Login.popup.loginPopUpVerifyEmail"));
        } else if (response.data === "error bd") {
          setShowAlert(true);
          setShowText(t("Login.popup.loginPopUpDatabaseError"));
        } else {
          setShowAlert(true);
          setShowText(t("Login.popup.loginPopUpSomethingWrong"));
        }
      })
      .catch((error) => {
        setShowAlert(true);
        setShowText(error.message);
      });
  };
  const onSuccessGoogleLogin = (codeResponse) => {
    const Id = codeResponse.access_token;
    setokenId(Id);
    profileData(Id);
  };

  const profileData = (tokenId) => {
    axios
      .get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      })
      .then((response) => {
        const { email } = response.data;
        loginWithGoogleAndMicrosoft(email);
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
      });
  };
  const login = useGoogleLogin({
    onSuccess: onSuccessGoogleLogin,
    // ... other options
  });
  const loginUser = async (email_, password_, google) => {
    console.log("login user");
    const user_ip = await getIP();
    let email = email_.toLowerCase();
    var correo = email;
    var contrasenia = password_;
    var arrayData = [];
    arrayData.push(correo);
    arrayData.push(contrasenia);
    arrayData.push(google);
    var dataForm = {
      data_eel: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, arrayData[0], {
        iter: 500,
      }),
      data_epd: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, arrayData[1], {
        iter: 500,
      }),
    };
    var dataFormJsonEll = JSON.parse(dataForm["data_eel"]);
    var dataFormJsonEpd = JSON.parse(dataForm["data_epd"]);

    if (arrayData[2] === true) {
      var dataForm_ = {
        data_eel: {
          data_eeli: dataFormJsonEll["iv"],
          data_eels: dataFormJsonEll["salt"],
          data_eelc: dataFormJsonEll["ct"],
        },
        data_epd: {
          data_epdi: dataFormJsonEpd["iv"],
          data_epds: dataFormJsonEpd["salt"],
          data_epdc: dataFormJsonEpd["ct"],
        },
        google: true,
      };
    } else {
      var dataForm_ = {
        data_eel: {
          data_eeli: dataFormJsonEll["iv"],
          data_eels: dataFormJsonEll["salt"],
          data_eelc: dataFormJsonEll["ct"],
        },
        data_epd: {
          data_epdi: dataFormJsonEpd["iv"],
          data_epds: dataFormJsonEpd["salt"],
          data_epdc: dataFormJsonEpd["ct"],
        },
      };
    }
    const formData = new FormData();

    // Append the data_eel and data_epd objects to the FormData
    formData.append("data_eel", JSON.stringify(dataForm_["data_eel"]));
    formData.append("data_epd", JSON.stringify(dataForm_["data_epd"]));
    formData.append("user_ip", user_ip);
    formData.append("user_device", navigator.userAgent);
    console.log("api calling...");
    console.log(formData, "formData");
    console.log("process.env.REACT_APP_BASE_URL2", process.env.REACT_APP_BASE_URL2);
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_URL2 + "ingresar",
      data: formData,

    })
      .then((response) => {
        console.log("response:", response);
        if (
          response.status == 200 &&
          typeof response.data === "object" &&
          response.data["2fa_required"]
        ) {
          navigate("/VerifyAccount", { state: { dataForm: dataForm_ } });
        } else if (
          response.status === 200 &&
          typeof response.data === "object" &&
          response.data.length != 0 &&
          response.data != "no existe"
        ) {
          Cookies.set("userInfo", response.data);
          {
            ( response.data[18] === "E_Module") ?
              (navigate("/Emodule"))
              :
              (navigate("/Dashboard"))
          }

        } else if (response.data === "User is already logged in") {
          setMultipleSessionFlag(true);
          setShowAlert(true);
          setShowText(t("thanks.useralready"));
        } else if (
          response.data === "no validado" &&
          typeof response.data === "string"
        ) {
          setShowAlert(true);
          setShowText(t("Login.popup.loginPopUpMessageNotValidEmail"));
        } else if (response.data === "registro no autorizado") {
          setShowAlert(true);
          setShowText(t("Login.popup.loginPopUpWaitingAuthorization"));
        } else if (response.data == "User is deactivated") {
          setShowAlert(true);
          setShowText(t("Login.popup.loginPopUpAccountDeactivated"));
        } else if (response.status == 200 && response.data == "no existe") {
          setShowAlert(true);
          setShowText(t("Login.popup.loginPopUpMessageWrongEmailPassword"));
        } else {
          setShowAlert(true);
          setShowText(t("Login.popup.loginPopUpSomethingWrong"));
        }
      })
      .catch((error) => {
        console.log("error area")
        console.log(error)
        setShowAlert(true);
        setShowText(error.message);
      });
  };
  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      <Box
        sx={{
          background: "#fff",
        }}
        id="particles-js"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                minHeight: "100vh",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <Box
                  sx={{
                    background: "#ffffff",
                    position: "relative",
                    zIndex: 1,
                    borderRadius: 1,
                    p: { xs: 2, sm: 4 },
                    width: { xs: "90%", sm: "500px" },
                    my: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <img src={logo} alt="logo" />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "24px",
                        lineHeight: "35px",
                        textAlign: "center",
                        color: "#1C212D",
                      }}
                    >
                      {t("Login.header.title")}
                    </Typography>
                    <Box sx={{ textAlign: "center", mt: -1 }}>
                      <img src={underLine} alt="under line" />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 4,
                      flexDirection: "column",
                    }}
                  >
                    <LabelInputLeftIcon
                      mailIcon={mailImage}
                      type="text"
                      label={t("Login.inputs.inputLabelEmail")}
                      name="emailAddress"
                      id="emailAddress"
                      placeholder={t("Login.inputs.inputTextEmail")}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\s/g, '');
                        setEmail(e.target.value);
                      }}
                      error={formErrors.email}
                    />
                    {formErrors.email && (
                      <Typography variant="body2" color="error">
                        {formErrors.email}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <PasswordShowHideIcon
                      type="text"
                      label={t("Login.inputs.inputLabelPassword")}
                      name="Password"
                      id="Password"
                      placeholder={t("Login.inputs.inputTextPassword")}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\s/g, '');
                        setPassword(e.target.value);
                      }}
                      error={formErrors.password}
                    />
                    {formErrors.password && (
                      <Typography variant="body2" color="error">
                        {formErrors.password}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      mt: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox defaultChecked />
                      <Typography sx={{ fontSize: { xs: "14px", lg: "16px" } }}>
                        {t("Login.inputs.checkBoxRememberLabel")}
                      </Typography>
                    </Box>
                    <Box>
                      <Link
                        to="/ForgotPassword"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          sx={{ fontSize: { xs: "14px", lg: "16px" } }}
                          variant="body1"
                        >
                          {t("Login.buttons.forgotPasswordLink")}
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <Button
                      id="loginSubmit"
                      onClick={handleClick}
                      sx={{
                        backgroundColor: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 10 },
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                      }}
                      variant="contained"
                    >
                      {t("buttons.login")}
                    </Button>
                  </Box>
                  <Divider
                    sx={{
                      mt: 3,
                      textAlign: "center",
                      fontSize: "16px",
                      color: "#000000",
                      fontFamily: "Gilroy-SemiBold",
                    }}
                  >
                    {t("Login.buttons.textBelowLoginButton")}
                  </Divider>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flex: "row",
                      mt: 2,
                    }}
                  >
                    <Link
                      onClick={() => login()}
                      style={{
                        border: "1px solid rgba(27, 32, 44, 0.1)",
                        borderRadius: "6px",
                        alignItems: "center",
                        display: "flex",
                        padding: "11px",
                      }}
                      href="#"
                    >
                      <img
                        src={google}
                        width={25}
                        height={25}
                        alt="google icon"
                      />
                    </Link>
                    <Link
                      onClick={handleMicrosoft}
                      style={{
                        marginLeft: "20px",
                        border: "1px solid rgba(27, 32, 44, 0.1)",
                        borderRadius: "6px",
                        alignItems: "center",
                        display: "flex",
                        padding: "11px",
                      }}
                      href="#"
                    >
                      <img
                        src={microSoft}
                        width={25}
                        height={25}
                        alt="google icon"
                      />
                    </Link>
                    {window.location.href.startsWith(
                      "https://jabil.searchlook.mx"
                    ) && (
                        <Link
                          onClick={() => {
                            loginWithOkta();
                          }}
                          style={{
                            marginLeft: "20px",
                            border: "1px solid rgba(27, 32, 44, 0.1)",
                            borderRadius: "6px",
                            alignItems: "center",
                            display: "flex",
                            padding: "11px",
                          }}
                          href="#"
                        >
                          <img
                            src={oktaimage}
                            width={25}
                            height={25}
                            alt="Okta icon"
                          />
                        </Link>
                      )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <Button
                      onClick={GuestUserHandler}
                      sx={{
                        borderColor: "primary.main",
                        color: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 6.5 },
                        fontSize: "16px",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize !important",
                      }}
                      variant="outlined"
                    >
                      {t("Login.buttons.buttonTextGuest")}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flex: "row",
                      mt: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#000000",
                        fontFamily: "Gilroy-Medium",
                        fontSize: "16px",
                      }}
                    >
                      {t("Login.buttons.textBelowGuestButton")}
                      <Link
                        to="/Signup"
                        style={{
                          color: "#4165F6",
                          textDecoration: "none",
                          marginLeft: "5px",
                        }}
                      >
                        {t("Login.buttons.signUpLink")}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              backgroundImage: `url(${LoginImage})`,
            }}
          >
            <AuthLeftScreen
              text={t("Login.buttons.signUpLink")}
              url="/Signup"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default SignIn;
