import { useTranslation } from "react-i18next";
import axios from "axios";
import { Grid, Typography, Box, Stack } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import InputRightIcon from "../@common/Input/InputRightIcon/InputRightIcon";
import personIcon from "../../assets/images/person.svg";
import DatePickerInput from "../@common/Input/DatePicker/DatePickerInput";
import DropDownInputSimple from "../@common/Input/DropDownInputSimple/DropDownInputSimple";
import LabelInput from "../@common/Input/LabelInput";
import RadioButtonsGroup from "../@common/Input/radios";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import GenerateQueryModal from "../@common/Modal/GenerateQueryModal";
import AlertModal from "../@common/Modal/AlertModal";
import EmailSuccessfullySent from "../@common/Modal/EmailSuccessfullySent";
import ThankYouMessage from "../@common/Modal/ThankYouMessage";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader";
import WithBureauQueryModal from "../@common/Modal/WithBureauModal/WithBureauQueryModal";
import Container from "@mui/material/Container";
import underLine from "../../assets/images/under-line.png";
import GuestHeader from "../@common/Header/GuestHeader";
import GuestFooter from "../../Footer/GuestFooter";
import GuestTermsAndConditionModal from "../@common/Modal/WithBureauModal/GuestTermsAndConditionModal";
import { options_edo } from "../../utils/optionsEdo";
import { formatDate } from "../../utils/dateFormatHelper";
import {
  AesEncrypt,
  getKeysSaltAndIv,
  setKeysDict,
  encryptCurpZeroPayload,
  prepareCurpPayload,
} from "../../utils/encryptionHelper";
import { getCookiesList } from "../../utils/cookiesHelper";
import { timeSleep, checkCurpZero } from "../../utils/bureauHelper";
import { call_api_function } from "../../utils/verifyAPICalls";
import AutoCompleteModal from "../@common/Modal/AutoCompleteModal";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import { serverRequest, serverRequest2 } from "../../utils/requestHelper";
import "./ToggleButton.css";
import AutocompleteBlue from "../../assets/images/Autocomplete Arrow Blue.svg";
import Autocompleteorange from "../../assets/images/Autocomplete Arrow Orange.svg";
import SwitchesCustomized from "../WithBureau/WithBureauQueryForm/SwitchButton";
function GuestGenerateQuery() {
  const { t } = useTranslation();
  const sexTypeList = [
    { label: t("labels.radioLabelFemale"), value: "Feminine" },
    { label: t("labels.radioLabelMale"), value: "Male" },
    { label: t("labels.radioLabelNonBinary"), value: "Non-Binary" },
  ];

  const activateLegalFilterList = [
    { label: t("labels.inputLabelYes"), value: "Yes" },
    { label: t("labels.inputLabelNo"), value: "No" },
  ];

  const multiReportTypeList = [
    { label: t("labels.radioLabelWithBureau"), value: "With Bureau" },
    { label: t("labels.radioLabelWithoutBureau"), value: "Without Bureau" },
  ];
  options_edo[0].name = t("placeholders.ddEdonto");
  var subsections_dict = {
    A: true,
    B: true,
    C: true,
    D: true,
    E: true,
    F: true,
    G: true,
    sub_a1: true,
    sub_a2: true,
    sub_a3: true,
    sub_a4: true,
    sub_a5: true,
    sub_a6: true,
    sub_a7: true,
    sub_a8: true,
    sub_a9: true,
    sub_b1: true,
    sub_b2: true,
    sub_b3: true,
    sub_b4: true,
    sub_b5: true,
    sub_b6: true,
    sub_b7: true,
    sub_b8: true,
    sub_b9: true,
    sub_b10: true,
    sub_c1: true,
    sub_c2: true,
    sub_c3: true,
    sub_c4: true,
    sub_c5: true,
    sub_c6: true,
    sub_c7: true,
    sub_c8: true,
    sub_d1: true,
    sub_d2: true,
    sub_d3: true,
    sub_d4: true,
    sub_d5: true,
    sub_d6: true,
    sub_d7: true,
    sub_d8: true,
    sub_d9: true,
    sub_e1: true,
    sub_e2: true,
    sub_e3: true,
    sub_e4: true,
    sub_e5: true,
    sub_f1: true,
    sub_f2: true,
    sub_f3: true,
    sub_f4: true,
    sub_f5: true,
    sub_f6: true,
    sub_g1: true,
    sub_g2: true,
    sub_g3: true,
    sub_g4: true,
  };

  const [cookieList, setCookieList] = useState([]);
  const [curp, setCurp] = useState("");
  const [error, setError] = useState('');
  const [name, setName] = useState("");
  const [last_name, setLastName] = useState("");
  const [mother_last_name, setMotherLastName] = useState("");
  const [sexType, setSexType] = useState("Feminine");
  const [date_of_brith, setDateOfBrith] = useState("");
  const [birthState, setBirthState] = useState("State");
  const [legalFiter, setLegalFiter] = useState("Yes");
  const [multiReportType, setMultiReportType] = useState("With Bureau");
  const [snsFlag, setSnsFlag] = useState(false);
  const [snsError, setSnsError] = useState("Field Required");
  const [idError, setIdError] = useState("Field Required");
  const [idFlag, setIdFlag] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [showTextFieldTwo, setShowTextFieldTwo] = useState(false);
  //Optional Form data
  const [sns, setSnS] = useState(""); //nss
  const [id, setId] = useState(""); //cedula

  const [curpFocus, setCurpFocus] = useState(false);
  const [nameFocus, setNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [motherLastNameFocus, setMotherLastNameFocus] = useState(false);

  const [curpChanged, setCurpChanged] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [lastNameChanged, setLastNameChanged] = useState(false);
  const [motherLastNameChanged, setMotherLastNameChanged] = useState(false);
  const [dateOfBrithChanged, setDateOfBrithChanged] = useState(false);
  const [birthStateChanged, setBirthStateChanged] = useState(false);
  //DataSend
  const [DataSend, setDataSend] = useState({});
  const [nbdata, setNBData] = useState({});
  // Handle Modals
  const [isLogoModalOpen, setLogoModalOpen] = useState(false);
  const [withoutBureauModalOpen, setWithoutBureauModalOpen] = useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const [emailSentModalOpen, setEmailSentModalOpen] = useState(false);
  // Report Data
  const [subsectionDicts, setSubSectionDicts] = useState(subsections_dict);
  const [reportData, setReportData] = useState({});
  const [showAlertModal, setShowAlertModal] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const isNameEmpty = name.trim() === "";
  const isLastNameEmpty = last_name.trim() === "";
  const isMotherNameEmpty = mother_last_name.trim() === "";
  const isbirthState =
    birthState.trim() === "State" || birthState.trim() === "Estado";
  const dateOfBirthPattern =
    /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19\d{2}|20[0-9]{2})$/;
  const isDateOfBirthValid =
    date_of_brith.trim() !== "" && dateOfBirthPattern.test(date_of_brith);
  const isDateBirthEmpty = !isDateOfBirthValid;
  const [isAutoComplete, setIsAutoComplete] = useState(false); // auto complete state
  const [loadFunction, setLoadFunction] = useState(true);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [appendData, setapeendData] = useState(false);
  const [curpACdata, setCurpACdata] = useState("");
  const [namedata, setNamedata] = useState("");
  const [last_namedata, setLastNamedata] = useState("");
  const [mother_last_namedata, setMotherLastNamedata] = useState("");
  const [sexTypedata, setSexTypedata] = useState("");
  const [date_of_brithdata, setDateOfBrithdata] = useState(""); // fecha de nacimiento
  const [birthStatedata, setBirthStatedata] = useState("");
  const [curpData, setcurpData] = useState("");
  const [estadoData, setestadoData] = useState("");
  const [nssData, setnssData] = useState("");
  const [nss1Data, setnss1Data] = useState("");
  const [cname, setcname] = useState("");
  const [edad, setedad] = useState("");
  const [showHomonyms, setHomonyms] = useState(false);

  const [ACType, setACType] = useState("");
  const [disableCURP, setDisableCURP] = useState(true);
  const [disableName, setDisableName] = useState(true);
  const [disableLastName, setDisableLastName] = useState(true);
  const [disableMotherLastName, setDisableMotherLastName] = useState(true);
  const [disableSexType, setDisableSexType] = useState(true);
  const [disableDateOfBirth, setDisableDateOfBirth] = useState(true);
  const [disableBirthState, setDisableBirthState] = useState(true);

  const handleCloseAlertModal = () => {
    setShowAlertModal(false);
    setLoadFunction(true);
  };
  const handleAcceptAlert = () => {
    setLoadFunction(false);
    setShowAlertModal(false);
    const gender = sexTypedata === "M" ? "Feminine" : sexTypedata === "X" ? "Non-Binary" : "Male";
    setCurp(curpACdata);
    setName(namedata);
    setLastName(last_namedata);
    setMotherLastName(mother_last_namedata);
    setSexType(gender);
    setDateOfBrith(date_of_brithdata);
    setBirthState(birthStatedata);
    updateDisabledFields();
  };

  const updateDisabledFields = (ac_type='') => {
    console.log(['ACType', ac_type||ACType, (ac_type||ACType) == "curp"]);
    if ((ac_type||ACType) == "curp") {
      setDisableCURP(false);
      setDisableName(true);
      setDisableLastName(true);
      setDisableMotherLastName(true);
      setDisableSexType(true);
      setDisableDateOfBirth(true);
      setDisableBirthState(true);
    } else {
                console.log('updateDisabledFields.disableCURP', disableCURP);
      setDisableCURP(true);
      setDisableName(false);
      setDisableLastName(false);
      setDisableMotherLastName(false);
      setDisableSexType(false);
      setDisableDateOfBirth(false);
      setDisableBirthState(false);
    }
  }

  const handleFocus = (inputId) => {
    console.log("handleFocus", inputId);
    if (inputId === "curp") {
      setCurpFocus(true);
      setCurpChanged(false);
    }
    if (inputId === "name") {
      setNameFocus(true);
      setNameChanged(false);
    } else if (inputId === "last_name") {
      setLastNameFocus(true);
      setLastNameChanged(false);
    } else if (inputId === "mother_last_name") {
      setMotherLastNameFocus(true);
      setMotherLastNameChanged(false);
    }
  };
  const handleBlur = (inputId) => {
    console.log("handleBlur", inputId);
    if (inputId === "curp") {
      setCurpFocus(false);
      console.log("curpChanged", curpChanged);
      if (curpChanged) {
        // fetchAutoCompleteModal();
      }
    } else if (inputId === "name") {
      setNameFocus(false);
      console.log("nameChanged", nameChanged);
      if (nameChanged) {
        // fetchAutoCompleteModal();
      }
    } else if (inputId === "last_name") {
      setLastNameFocus(false);
      console.log("lastNameChanged", lastNameChanged);
      if (lastNameChanged) {
        // fetchAutoCompleteModal();
      }
    } else if (inputId === "mother_last_name") {
      setMotherLastNameFocus(false);
      console.log("motherLastNameChanged", motherLastNameChanged);
      if (motherLastNameChanged) {
        // fetchAutoCompleteModal();
      }
    }
  };
  // Effects
  useEffect(() => {
    const userCookiesList = getCookiesList("guestUserInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);
  useEffect(() => {
    if (Object.keys(DataSend).length !== 0) {
      getCurpZero(DataSend);
    }
  }, [DataSend]);
  useEffect(() => {
    if (subsectionDicts !== subsections_dict) {
      setWithoutBureauModalOpen(true);
    }
  }, [subsectionDicts]);

  const legalFilterHandler = useCallback((e) => {
    setLegalFiter(legalFiter == e.target.value ? "" : e.target.value);
  });

  const multiReportTypeHandler = useCallback((e) => {
    setMultiReportType(multiReportType == e.target.value ? "" : e.target.value);
  });
  // Logo modal Handlers
  const handleOpenLogoModal = () => {
    setLogoModalOpen(true);
  };

  const handleCloseLogoModal = () => {
    setLogoModalOpen(false);
  };

  // when workflow of thank you modal is on
  const openThankYouModal = (data) => {
    setReportData(data);
    setIsThankYouModalOpen(true);
  };

  const consultHandler = () => {
    handleOpenLogoModal();
    encryptData();
  };

  const resetSubsections = (tipoCupon) => {
    var sub_dict = subsections_dict;

    if (tipoCupon == "CB") {
      Object.keys(sub_dict).forEach(function (key) {
        sub_dict[key] = true;
      });
    } else if (tipoCupon == "SB") {
      Object.keys(sub_dict).forEach(function (key) {
        sub_dict[key] = true;
      });
      sub_dict["D"] =
        sub_dict["sub_d1"] =
        sub_dict["sub_d2"] =
        sub_dict["sub_d3"] =
        sub_dict["sub_d4"] =
        sub_dict["sub_d5"] =
        sub_dict["sub_d6"] =
        sub_dict["sub_d7"] =
        sub_dict["sub_d8"] =
        sub_dict["sub_d9"] =
        sub_dict["sub_g3"] =
        false;
    }

    return sub_dict;
  };

  const encryptData = () => {
    // const consult_data_array =
    var filterLegalNo = "";
    if (legalFiter === "Yes") {
      filterLegalNo = "Si";
    } else {
      filterLegalNo = "";
    }
    var genderNo = "";
    if (sexType === "Feminine") {
      genderNo = "M";
    }  else if (sexType === "Non-Binary") {
      genderNo = "X";
    } else {
      genderNo = "H";
    }
    var arr_date_of_brith = [];
    const formateDateOfBirth = formatDate(date_of_brith);
    arr_date_of_brith = formateDateOfBirth.split("/");
    const day_birth = arr_date_of_brith[0];
    const month_birth = arr_date_of_brith[1];
    const year_birth = arr_date_of_brith[2];
    var userAgent = navigator.userAgent; //The user agent string contains information about the browser and operating system being used by the user to access the web page. It includes details such as the name and version of the browser, as well as the device and operating system being used.

    var subject_name = name + " " + last_name + " " + mother_last_name;
    const formData = {
      name: name,
      last_name: last_name,
      curp_zero: curp,
      mother_last_name: mother_last_name,
      day_birth: day_birth,
      month_birth: month_birth,
      year_birth: year_birth,
      genderNo: genderNo,
      sns: sns,
      id: id,
      scl: "1",
      email: "",
      phone: "",
      birthState: birthState,
      street: "NA",
      cologne: "NA",
      municipality: "NA",
      postal_code: "NA",
      state: "NA",
      filterLegalNo: filterLegalNo,
      user_tjt: "NA",
      user_master: "NA",
      user_customer: "NA",
      userAgent: navigator.userAgent,
    };
    const user_type = "guest";
    const dS = prepareCurpPayload(
      formData,
      cookieList,
      subsections_dict,
      user_type
    );

    setDataSend(dS);
  };

  const getCurpZero = async (dataCurpZero) => {
    const endpoint = process.env.REACT_APP_BASE_URL2 + "curp_zero";
    var continuarCurpZero = false;
    const response = await axios.post(endpoint, dataCurpZero, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.data.mensaje === "Guardado correctamente") {
      continuarCurpZero = true;
      nextStep(response.data._id, continuarCurpZero);
    }
  };

  const nextStep = async (insertedId, continuarCurpZero) => {
    if (continuarCurpZero === true) {
      try {
        await timeSleep();
      } catch (e) {
        setShowAlert(true);
        setShowtext("Desconexión de red. Continúa");
      }
      var i_curpzero = 0;
      var i_flag = false;
      while (i_flag === false) {
        var returnCurpZero = await checkCurpZero(insertedId);
        if (returnCurpZero.pending === false) {
          if (returnCurpZero.final_response.status === "1") {
            // Limpiar inputs
            /* Persona bloqueada */
            if (returnCurpZero.final_response.curps._id == "Bloqueado") {
              setShowAlert(true);
              setShowtext("Bloqueado");
            } else {
              var data = {
                _id: returnCurpZero.final_response.curps._id,
                nombre: returnCurpZero.final_response.cname,
                email: cookieList[3],
                sucursal: returnCurpZero.petition.sujeto.sucursal,
                zona: returnCurpZero.petition.sujeto.zona,
                staff: returnCurpZero.petition.sujeto.staff,
                filtro_legal: returnCurpZero.petition.sujeto.filtro_legal,
                cliente: returnCurpZero.petition.sujeto.cliente,
                estado: returnCurpZero.petition.sujeto.buro.estado,
                colonia: returnCurpZero.petition.sujeto.buro.colonia,
                municipio: returnCurpZero.petition.sujeto.buro.municipio,
              };
              setNBData(data);
              handleCloseLogoModal();
              const sub_dict = resetSubsections("SB");
              setSubSectionDicts(sub_dict);
              // validarTipoConsulta();
            }
          } else if (returnCurpZero.final_response.status === "varios") {
            setcurpData(returnCurpZero["final_response"]["curps"][0]["curp"]);
            setestadoData(
              returnCurpZero["final_response"]["curps"][0]["estado"]
            );
            setnssData(returnCurpZero["final_response"]["curps"][0]["nss"]);
            setnss1Data(returnCurpZero["final_response"]["curps"][1]["nss"]);
            setcname(returnCurpZero["final_response"]["curps"][0]["cname"]);
            setedad(returnCurpZero["final_response"]["curps"][0]["edad"]);
            setHomonyms(true);
            var data = {
              _id: returnCurpZero.final_response.curps[0]._id,
              nombre: returnCurpZero.final_response.cname,
              email: cookieList[3],
            };
            setNBData(data);
            handleCloseLogoModal();
            const sub_dict = resetSubsections("SB");
            setSubSectionDicts(sub_dict);
          } else if (
            returnCurpZero.final_response.error === "error_en_los_datos"
          ) {
            // Borde naranja en inputs
            setShowAlert(true);
            setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
            handleCloseLogoModal();
          } else {
            // Borde naranja en inputs
            setShowAlert(true);
            setShowtext("else");
            handleCloseLogoModal();
          }
          i_flag = true;
          insertedId = false;
          returnCurpZero = false;
        } else if (returnCurpZero.pending === true) {
          try {
            await timeSleep();
          } catch (e) {
            setShowAlert(true);
            setShowtext("Desconexión de red. Continúa");
          }
        }
        i_curpzero++;
        if (i_curpzero === 20 && i_flag === false) {
          i_flag = true;
          insertedId = false;
          returnCurpZero = false;
          handleCloseLogoModal();
          setShowAlert(true);
          setShowtext("max reached");
        }
      }
    }
  };

  const snsHandler = (e) => {
    const reg = /^[0-9]*$/;
    const input = e.target.value; // Remove leading and trailing spaces
    const validReg = reg.test(input);
    if (validReg) {
      setSnsFlag(false);
    } else {
      setSnsFlag(true);
      setSnsError(t("thanks.enternumber"));
    }
    setSnS(input);
  };
  const idHandler = (e) => {
    const reg = /^[0-9]*$/;
    const input = e.target.value; // Remove leading and trailing spaces
    const validReg = reg.test(input);
    if (validReg) {
      setIdFlag(false);
    } else {
      setIdFlag(true);
      setIdError(t("thanks.enternumber"));
    }
    setId(input);
  };
  const handleCheckboxChange = (event) => {
    setShowTextField(event.target.checked);
  };
  const handleCheckboxTwoChange = (event) => {
    setShowTextFieldTwo(event.target.checked);
  };

  const loadAutoComplete = async () => {
    let formData = new FormData();
    formData.append("email", cookieList[3]);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL2 + "api/autocomplete_load",
      formData,
      {
        header: {
          "Content-Type": "multipart/form-data",
        },
      },
      { timeout: 10000 }
    );
    if (response.data) {
      setIsAutoComplete(true);
    }
  };
  const saveAutoComplete = async () => {
    let formData = new FormData();
    formData.append("email", cookieList[3]);
    formData.append("active", isAutoComplete);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL2 + "api/autocomplete_save",
      formData,
      {
        header: {
          "Content-Type": "multipart/form-data",
        },
      },
      { timeout: 10000 }
    );
    if (response.data === "Done") {
      loadAutoComplete();
    }
  };
  useEffect(() => {
    if (cookieList.length != 0) {
      loadAutoComplete();
    }
  }, [cookieList]);


  const fetchAutoCompleteModal = async () => {
    try {
      if (loadFunction) {
        if (
          (curp && curp.length === 18) || (
            name !== "" &&
            last_name !== "" &&
            sexType !== "" &&
            birthState !== "State" &&
            dateOfBirthPattern.test(date_of_brith) &&
            birthState !== "" &&
            date_of_brith !== ""
          )
        ) {
          let formData = new FormData();
          formData.append("curp", curp);
          formData.append("name", name);
          formData.append("lname", last_name);
          formData.append("slname", mother_last_name);
          formData.append("birth_state", birthState);
          formData.append("date_of_birth", date_of_brith);
          formData.append("gender", sexType === "Feminine" ? "M" : sexType === "Non-Binary" ? "X" : "H");
          formData.append("empresa", cookieList[13]);
          const response = await axios.post(
            process.env.REACT_APP_BASE_URL2 + "api/autocomplete",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              timeout: 10000,
            }
          );
          let valid_data;
          if (
            response.data.curp_based && 
            response.data.curp_based != 'No encontrado'
          ) {
            setACType("curp");
            if (response.data.curp_based.match < 8) {
              valid_data = response.data.curp_based;
            }
          } else {
            if (isAutoComplete) {
              if ( response.data.count_based.name ) {
                setACType("count");
                if (response.data.count_based.match < 8) {
                  valid_data = response.data.count_based;
                } else {
                  setLoadFunction(false);
                  setCurp(response.data.count_based.curp);
                  updateDisabledFields('count');
                }
              }
            } else {
              if ( response.data.match_based.name ) {
                setACType("match");
                if (response.data.match_based.match == 7) {
                  valid_data = response.data.match_based;
                }
              }
            }
          }
          if (valid_data) {
            setCurpACdata(valid_data.curp);
            setNamedata(valid_data.name);
            setLastNamedata(valid_data.lname);
            setMotherLastNamedata(valid_data.slname);
            setSexTypedata(valid_data.gender);
            setDateOfBrithdata(valid_data.date_of_birth);
            setBirthStatedata(valid_data.birth_state);
            setShowAlertModal(true);
          }
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (
      (curp && curp.length === 18) || (
        !nameFocus &&
        !lastNameFocus &&
        !motherLastNameFocus &&
        name !== "" &&
        last_name !== "" &&
        sexType !== "" &&
        birthState !== "State" &&
        dateOfBirthPattern.test(date_of_brith) &&
        birthState !== "" &&
        date_of_brith !== ""
      )
    ) {
      console.log([ curp, nameFocus, lastNameFocus, motherLastNameFocus, sexType, date_of_brith, birthState, isAutoComplete, ]);
      fetchAutoCompleteModal();
    }
  }, [
    curp,
    nameFocus,
    lastNameFocus,
    motherLastNameFocus,
    sexType,
    date_of_brith,
    birthState,
    isAutoComplete,
  ]);

  useEffect(() => {
    if (isAutoComplete) {
      setLoadFunction(true);
    }
  }, [isAutoComplete]);

  const [changeColor, setChangeColor] = useState(false);

  return (
    <>
      {showAlertModal && (
        <AutoCompleteModal
          curp={curpACdata}
          name={namedata}
          last_name={last_namedata}
          mother_last_name={mother_last_namedata}
          date_of_brith={date_of_brithdata}
          sexType={sexTypedata}
          birthState={birthStatedata}
          onClose={handleCloseAlertModal}
          onAccept={handleAcceptAlert}
        />
      )}
      <GuestHeader />
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      <Box sx={{ backgroundColor: "#FAFCFF", p: 4 }}>
        <Box mt={13}>
          <Box mb={4}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("GuestDashboard.header")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
          <Box sx={{ bgcolor: "#fff", p: 2, borderRadius: 2 }}>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box>
                <SwitchesCustomized
                  isAutoComplete={isAutoComplete}
                  toggleButton={(switchState) => {
                    setLoadFunction(true);
                    setIsAutoComplete(switchState);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box pb={2} mt={-1}>

                <InputRightIcon
                  disabled={
                    (name || last_name || mother_last_name || date_of_brith || birthState != 'State') && disableCURP
                  }
                  mailIcon={personIcon} // Assuming personIcon is defined somewhere
                  type="text"
                  label={t("labels.inputLabelCurp")}
                  name="curp"
                  id="Curp"
                  placeholder={t("labels.inputLabelCurp")}
                  onChange={(event) => {
                    setLoadFunction(true);
    
                    setCurp(event.target.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase());
                    setCurpChanged(true);
                    setName('');
                    setLastName('');
                    setMotherLastName('');
                    setSexType('Feminine');
                    setDateOfBrith('');
                    setBirthState('State');
                    
                    setDisableName(true);
                    setDisableLastName(true);
                    setDisableMotherLastName(true);
                    setDisableSexType(true);
                    setDisableDateOfBirth(true);
                    setDisableBirthState(true);
                  }}
                  onBlur={() => { handleBlur('curp') }}
                  onFocus={() => { handleFocus('curp') }}
                  maxLength={18} // Add maxLength attribute to restrict input to 18 characters
                />
                </Box>
            </Grid>
            {error && (
          <Grid item xs={12}>
            <Box mt={{ xs: 2, sm: 0 }} style={{ color: 'red' }}>
              {error}
            </Box>
          </Grid>
        )}
              
          </Grid>




            {/* </Stack> */}
            <Grid

              container
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <Box>
                  <InputRightIcon
                    disabled={curp && disableName}
                    mailIcon={personIcon}
                    type="text"
                    label={t("labels.inputLabelName")}
                    name="name"
                    id="Name"
                    placeholder={t("placeholders.name")}
                    inputValue={name}
                    onChange={(event) => {
                      setLoadFunction(true);
      
                      setName(event.target.value);
                      setNameChanged(true);
                      setCurp('');
      
                      console.log('disableCURP', disableCURP);
                      setDisableCURP(true);
                    }}
                    onBlur={() => { handleBlur('name') }}
                    onFocus={() => { handleFocus('name') }}
                    mandatory={true}
                    FilledOut={curp && curp.length === 18}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box>
                  <InputRightIcon
                    disabled={curp && disableLastName}
                    mailIcon={personIcon}
                    type="text"
                    label={t("labels.inputLabelLastName")}
                    name="lname"
                    id="lname"
                    placeholder={t("placeholders.lastname")}
                    inputValue={last_name}
                    onChange={(event) => {
                      setLoadFunction(true);
      
                      setLastName(event.target.value);
                      setLastNameChanged(true);
                      setCurp('');
      
                      console.log('disableCURP', disableCURP);
                      setDisableCURP(true);
                    }}
                    onBlur={() => { handleBlur('last_name') }}
                    onFocus={() => { handleFocus('last_name') }}
                    mandatory={true}
                    FilledOut={curp && curp.length === 18}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 0 }}>
                  <InputRightIcon
                    disabled={curp && disableMotherLastName}
                    mailIcon={personIcon}
                    type="text"
                    label={t("labels.inputLabelMotherName")}
                    name="motherlastname"
                    id="motherlastname"
                    placeholder={t("placeholders.mothername")}
                    inputValue={mother_last_name}
                    onChange={(event) => {
                      setLoadFunction(true);
      
                      setMotherLastName(event.target.value);
                      setMotherLastNameChanged(true);
                      setCurp('');
      
                      console.log('disableCURP', disableCURP);
                      setDisableCURP(true);
                    }}
                    onBlur={() => { handleBlur('mother_last_name') }}
                    onFocus={() => { handleFocus('mother_last_name') }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ mt: { xs: 0, md: 3 } }}>
                  <RadioButtonsGroup
                    disabled={curp && disableSexType}
                    topLabelName={t("labels.topLabelSex")}
                    topLabel={true}
                    GroupingList={sexTypeList}
                    value={sexType}
                    onChange={(event) => {
                      setLoadFunction(true);
      
                      setSexType(sexType == event.target.value ? "" : event.target.value);
                      setCurp('');
      
                      console.log('disableCURP', disableCURP);
                      setDisableCURP(true);
                    }}
                    smGrid={5}
                    xs={6}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ mt: { xs: 0, md: 3 } }}>
                  <RadioButtonsGroup
                    topLabelName={t("labels.inputActiveLegalFilter")}
                    topLabel={true}
                    GroupingList={activateLegalFilterList}
                    value={legalFiter}
                    onChange={legalFilterHandler}
                    smGrid={5}
                    xs={6}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 0 }}>
                  <DatePickerInput
                    disabled={curp && disableDateOfBirth}
                    selectedDate={date_of_brith}
                    onDateChange={(value) => {
                      setLoadFunction(true);
      
                      setDateOfBrith(value);
                      setDateOfBrithChanged(true);
                      setCurp('');
      
                      console.log('disableCURP', disableCURP);
                      setDisableCURP(true);
                    }}
                    mandatory={true}
                    FilledOut={curp && curp.length === 18}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 0 }}>
                  <DropDownInputSimple
                    disabled={curp && disableBirthState}
                    options_data={options_edo}
                    type="text"
                    name="companyname"
                    subLabel={t("sublabels.mandatory")}
                    id="companyname"
                    label={t("labels.inputLabelEdoNto")}
                    inputValue={birthState}
                    onChange={(event) => {
                      setLoadFunction(true);
      
                      setBirthState(event.target.value);
                      setBirthStateChanged(true);
                      setChangeColor(true);
                      setCurp('');
      
                      console.log('disableCURP', disableCURP);
                      setDisableCURP(true);
                    }}
                    mandatory={true}
                    FilledOut={curp && curp.length === 18}
                    selectedColor={changeColor}

                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: { xs: 0, md: 3 } }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showTextField}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={t("labels.inputLabelSnsOptional")}
                  // labelPlacement="start" // Adjust the label placement as needed
                  />
                  {showTextField && (
                    <LabelInput
                      type="number"
                      subLabel=""
                      label=""
                      name="SNS"
                      id="SNS"
                      placeholder={t("placeholders.sns")}
                      minLength={11}
                      maxLength={11}
                      onChange={snsHandler}
                    />
                  )}
                </Box>
                {snsFlag && (
                  <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                    {snsError}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: { xs: 0, md: 3 } }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showTextFieldTwo}
                        onChange={handleCheckboxTwoChange}
                      />
                    }
                    label={t("labels.inputLabelIdOptional")}
                  // labelPlacement="start" // Adjust the label placement as needed
                  />
                  {showTextFieldTwo && (
                    <Box sx={{ mt: 0 }}>
                      <LabelInput
                        type="number"
                        subLabel=""
                        label=""
                        name="ID"
                        id="ID"
                        placeholder={t("placeholders.id")}
                        maxLength={8}
                        onChange={idHandler}
                      />
                    </Box>
                  )}
                </Box>
                {idFlag && (
                  <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                    {idError}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "primary.main",
                      textAlign: "center",
                      borderRadius: "6px",
                      py: 1.5,
                      px: 8,
                      color: "#fff",
                      textDecoration: "none",
                      fontSize: 18,
                      fontFamily: "Gilroy-Medium",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      if (curp && curp.length < 18) {
                        setError(t("labels.inputLabelCurpError"));
                      }
                      else {
                        consultHandler();
                        saveAutoComplete();
                      }
                    }}
                    disabled={
                      !(curp && curp.length === 18) ? (
                        isNameEmpty ||
                        isLastNameEmpty ||
                        isDateBirthEmpty ||
                        isbirthState ||
                      !(showTextField ? sns.length==11 : true)
                      ) : false
                    }

                  >
                    {t("buttons.consult")}
                  </Button>

                  {/************************** All Modal imported *************************/}
                  <LogoModalLoader isOpenLogoModal={isLogoModalOpen} />
                  {/* <GuestTermsAndConditionModal
                 tagLineShow={true}
                 subjectCheckboxShow={true}
                 emailBoxButtonShow={true}
                 emailLabel="Mail for sending Terms and Conditions / Privacy Notice"
                 /> */}
                  <EmailSuccessfullySent
                    text="Lorem ipsum dolor sit amet, consectetuer 
                 adipiscing elit. Sed atturpis vitae velit euismod "
                    isOpen={emailSentModalOpen}
                    setEmailSentModalOpen={setEmailSentModalOpen}
                    setWithoutBureauModalOpen={setWithoutBureauModalOpen}
                  />

                  <GuestTermsAndConditionModal
                    withoutBureauModalOpen={withoutBureauModalOpen}
                    setWithoutBureauModalOpen={setWithoutBureauModalOpen}
                    tagLineShow={true}
                    subjectCheckboxShow={true}
                    emailBoxButtonShow={true}
                    guestUser={true}
                    emailLabel={t("thanks.Email receipt")}
                    dataCurpZero={DataSend}
                    nbData={nbdata}
                    onConfirmation={openThankYouModal}
                    setEmailSentModalOpen={setEmailSentModalOpen}
                    subsections_dicts={subsectionDicts}
                    showHomonyms={showHomonyms}
                    nss1Data={nss1Data}
                    nssData={nssData}
                    estadoData={estadoData}
                    curpData={curpData}
                    cname={cname}
                    edad={edad}
                  />
                  <ThankYouMessage
                    guestUser={true}
                    isOpen={isThankYouModalOpen}
                    reportInfo={reportData}
                    subsetions_dicts={subsectionDicts}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <GuestFooter />
    </>
  );
}

export default GuestGenerateQuery;
