import { Box, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import Brightness1Icon from '@mui/icons-material/Brightness1';
function HeadingWithIcon(props) {
    const { text="" } = props
  return (
    <Fragment>
        <Box sx={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
        <Brightness1Icon sx={{color:"primary.light",fontSize:12,mr:1}}/>
        <Typography sx={{fontSize:16,fontFamily:"Gilroy-Regular",color:"text.opacity"}}>{text}</Typography>
        </Box>
    </Fragment>
  )
}

export default HeadingWithIcon